import React from 'react';
import PageHeader from "./ProdManagHeader";
import './ProductManagement.css'; // Importiere eine CSS-Datei für Styles

function ProductManagement() {
  return (
    <>
      <PageHeader />
      <br/><br/><br/>
      <div className="product-management-container">
        <h1 className="page-title">Wartung in Bearbeitung</h1>
        <p className="maintenance-message">
          Wir führen gerade Wartungsarbeiten durch. Die Seite steht bald wieder zur Verfügung. Vielen Dank für Ihr Verständnis.
        </p>
        <div className="image-container">
          <img className="centered-image" src="/undraw_maintenance_re_59vn.svg" alt="Wartungsgrafik" />
        </div>
      </div>
    </>
  );
}

export default ProductManagement;
