import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import { getStorage, ref  } from "firebase/storage";
import "firebase/storage";

  const firebaseConfig = {
    // apiKey: "AIzaSyDycgBKFmqo8X_0mq72tBSjNV77-L9yO8Q",
    // authDomain: "vendorsbrand-dev.firebaseapp.com",
    // databaseURL: "https://vendorsbrand-dev-default-rtdb.europe-west1.firebasedatabase.app",
    // projectId: "vendorsbrand-dev",
    // storageBucket: "vendorsbrand-dev.appspot.com",
    // messagingSenderId: "759123192862",
    // appId: "1:759123192862:web:cf54a05260a4c037f1120f",
    // measurementId: "G-18XH36C0YT"

    apiKey: "AIzaSyCSq6PkU8UjQvMfU0fNldsyak6Dkr0_cls",
    authDomain: "vendorsbrand-productionmode.firebaseapp.com",
    databaseURL: "https://vendorsbrand-productionmode-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "vendorsbrand-productionmode",
    storageBucket: "vendorsbrand-productionmode.appspot.com",
    messagingSenderId: "497851802749",
    appId: "1:497851802749:web:5f271eeb560f2ddb6b3d14",
    measurementId: "G-8GN1FZYSYD"
  };

  const app = firebase.initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
  const auth = getAuth(app);
  export const firestore = firebase.firestore();
  const analytics = firebase.analytics();

  // Initialize Cloud Storage and get a reference to the service
  const storage = getStorage(app);
  export {firebaseConfig, storage, firebase as default };
 