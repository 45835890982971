import React from 'react';
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import OrdersTable from '../SellerDashboard/Orders.Table'
import DashboardHeader from "./DashboardHeader";
import DashHeader from "./DashHeader";
function Dashboard() {

    return (
        <>
        {/* <DashboardHeader /> */}
        <DashHeader />
        <br/><br/><br/>
        <OrdersTable />
    {/* <Footer /> */}
        </>
    )
}

export default Dashboard

