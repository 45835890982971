// AboutUs.js
import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./About_Nearwide.css"; // Stildefinitionen
import Footer from "../../components/Footer/Footer.js";

const Local_Delivery = () => {
  return (
    <div className="wrapper">
      <header>
        <div className="header-content">
          <Link to="/" style={{ textDecoration: "none" }}>
            <h1
              className="logo"
              style={{ color: "#007FFF", cursor: "pointer" }}
            >
              Nearwide
            </h1>
          </Link>
          <br />
        </div>
      </header>
      <section className="content">
        <h2 className="header">Lokale Zustellung</h2>
        <br />
        <p>
          Wenn du bei Nearwide lokal einkaufst, erhältst du deine Bestellung
          ohne zusätzliche Kosten bereits am nächsten Tag.
        </p>{" "}
       
        <br />
        <br />
        <Link to="/">Zurück</Link>
      </section>
      <Footer />
    </div>
  );
};

export default Local_Delivery;
