import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Menü
      </Typography>
      <Divider />
      <List>
        
          <ListItem  disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              {/* <ListItemText primary={item} /> */}
              <Button

        // variant="outlined"
        startIcon={<HomeOutlinedIcon style={{ fontSize: "25px" }} />}
        style={{ textTransform: "none",  minWidth:'100px', fontWeight:'bold', fontSize: "20px"}}
        onClick={() => {
          navigate("/");
          
        }}
     >
        Startseite
      </Button>
            </ListItemButton>
          </ListItem>


          {/* <ListItem  disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
             
              Home2
            </ListItemButton>
          </ListItem> */}
       
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" elevation={1} sx={{ bgcolor: "#fff", color: "#007FFF" }} >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            style={{ fontFamily: "Arial, serif" }}
            variant="h5"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Nearwide 
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Button
        variant="outlined"
        // endIcon={<HomeOutlinedIcon style={{ fontSize: "25px" }} />}
        style={{ textTransform: "none" }}
        onClick={() => {
          navigate("/");
          
        }}
      >
        Startseite
      </Button>
          </Box>
          {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Button sx={{ color: "#00000" }}>
           Home2 
          </Button>
          </Box> */}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth
            }
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
   
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export default DrawerAppBar;
