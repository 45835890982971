import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

export default function DrawerAppBar(Props) {
  const { window } = Props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNearwideClick = () => {
    // Navigate to the homepage when Nearwide is clicked
    navigate("/");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography
        style={{ fontWeight: "bold", color: "rgb(0, 127, 255)" }}
        variant="h6"
        sx={{ my: 2 }}
      >
        Nearwide
      </Typography>
      <Divider />
      <List>

      <Button
          style={{ textTransform: "none", color: "rgb(101, 98, 98)" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Startseite
        </Button>



        <Button
          style={{ textTransform: "none", color: "rgb(101, 98, 98)" }}
          onClick={() => {
            navigate("/Produkte/hochladen");
          }}
        >
          Produkte hinzufügen
        </Button>

        <Button
          style={{ textTransform: "none", color: "rgb(101, 98, 98)" }}
          onClick={() => {
            navigate("/Produkte/verwaltung");
          }}
        >
          Produkte Verwaltung
        </Button>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" style={{ background: "#fff" }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1, display: { sm: "none" } }}
            style={{ color: "rgb(138, 141, 145)" }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ mr: 2, display: { sm: "none" } }}
            style={{
              background: "#fff",
              fontWeight: "bold",
              color: "rgb(0, 127, 255)",
            }}
          >
            Nearwide
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              cursor: "pointer",
            }}
            style={{ fontWeight: "bold", color: "rgb(0, 127, 255)" }}
            onClick={handleNearwideClick} // pass function reference
          >
            Nearwide
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              style={{
                textTransform: "none",
                color: "rgb(0, 127, 255)",
                marginRight: "10px",
              }}
              onClick={() => {
                navigate("/Produkte/hochladen");
              }}
              variant="outlined"
            >
              Produkte hinzufügen
            </Button>

            <Button
              style={{ textTransform: "none", color: "rgb(0, 127, 255)" }}
              onClick={() => {
                navigate("/Produkte/verwaltung");
              }}
              variant="outlined"
            >
              Produkte Verwaltung
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
