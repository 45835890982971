import React from "react";
import {useState, useEffect } from "react";
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import "./OrderPageTables.css";
import './Orders.Table.css'
import DashboardHeader from "./DashboardHeader";
import { firestore } from "../../firebaseConfig";
import firebase from "../../firebaseConfig";
import "./OrderPage.css";

function OrdersTable() {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState('');

  const [fullName, setfullName] = useState('');
  const [mobileNumber, setmobileNumber] = useState('');
  const [Produktname, setProduktname] = useState('');
  const [orderNumber,  setorderNumber] = useState('');
  const [cityName, setcityName] = useState('');
  const [streetName,  setstreetName] = useState('');
  const [houseNumber, sethouseNumber] = useState('');
  const [ProductURL,  setProductURL] = useState('');
  const [postalCode,  setpostalCode] = useState('');
  // const navigate = useNavigate();
  var url = window.location.pathname;
  var orderID = url.substring(url.lastIndexOf('/') + 1);
  // console.log(orderID)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
       const UserID = user.uid;

       const getProductsFromFirebase = [];
       const unsubscribe = firestore
         .collection("orders")
         .where("orderId", "==", orderID)
         .onSnapshot((querySnapshot) => {
           querySnapshot.forEach((doc) => {
            setProduct(querySnapshot.docs.map(doc => ({data: doc.data()})));
            setfullName(doc.data().firstandLastname)
            setorderNumber(doc.data().orderNumber)
            setProduktname(doc.data().Produktname)
            setstreetName(doc.data().streetName)
            sethouseNumber(doc.data().houseNumber)
            setpostalCode(doc.data().postalCode)
            setcityName(doc.data().cityName)
            setProductURL(doc.data().ProductURL)
            // console.log(doc.data().UID)
            
           });
          
           console.log(getProductsFromFirebase);
           setLoading(false);
         });
       // return cleanup function
    return () => unsubscribe();
      } else {
        // User not logged in or has just logged out.
      }
    });
  }, [loading]); // empty dependencies array => useEffect only called once

    return (
        <>
        <DashboardHeader  />
       <br/><br/><br/>
 <h1 className="h1Title">Bestellung Nr. {orderNumber}</h1>
 <div className="container">

<div className="order">
<table id="customers">

<th>Produktname</th>

{/* <th>Bestellungsstatus</th> */}

<tr>
  {product.Produktname}
  <td>{Produktname}</td>
  {/* <td>unterwegs</td> */}
</tr>
</table>
    </div>

    <h3 className="h3Title">Lieferungsadresse</h3>
    <div style={{overflowX:"auto"}} className="shipping-address">
   
  <table style={{textAlign:"center"}} >
    <tr style={{textAlign:"center"}}>
      <th style={{textAlign:"center"}}>Kundenname</th>
      <th style={{textAlign:"center"}}>Straße</th>
      <th style={{textAlign:"center"}}>Hausnummer</th>
      <th style={{textAlign:"center"}}>Postleitzahl</th>
      <th style={{textAlign:"center"}}>Stadt</th>
    </tr>
    <tr>
      <td style={{textAlign:"center"}}>{fullName}</td>
      <td style={{textAlign:"center"}}>{streetName}</td>
      <td style={{textAlign:"center"}}>{houseNumber}</td>
      <td style={{textAlign:"center"}}>{postalCode}</td>
      <td style={{textAlign:"center"}}>{cityName}</td>
    </tr>
  </table>
</div>


    <div className="contact-details">
    {/* <h3 className="h3Title">Kontaktdaten</h3> */}
    <table id="customers">
   
    {/* <tr>
    <td>015755437700</td>
    </tr> */}
    </table><br />
    <div className="ProductLink">
    <a target="_blank" href={ProductURL}>Das Produkt anzeigen</a>
    </div>
    </div>
    
 </div>


{/* <Footer /> */}
</>
    )
}

export default OrdersTable

