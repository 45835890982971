import React, { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import Alert from "@mui/material/Alert";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://firebasestorage.googleapis.com/v0/b/vendorsbrand-productionmode.appspot.com/o/LOGO%2FnearwideLogo.png?alt=media&token=dd56c57b-2d2d-4b8c-b83f-e9bca7aa9cff)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modalPaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    padding: "45px",
  },
  closeBtn: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: "pointer",
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [wrongPassword, setWrongPassword] = useState("");
  const [accountNotFound, setAccountNotFound] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordEmpty, setPasswordEmpty] = useState("");
  const [emailEmpty, setEmailEmpty] = useState("");
  const [emailAndPasswordEmpty, setEmailAndPasswordEmpty] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((firebaseUser) => {
        console.log("Login is successful");
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === "auth/wrong-password") {
          setWrongPassword("Das eingegebene Kennwort ist falsch.");
        }
        if (errorCode === "auth/user-not-found") {
          setAccountNotFound(
            "Es konnte kein Konto mit dieser E-Mail-Adresse gefunden werden."
          );
        } else {
          setError(errorMessage);
        }
      });

    setLoading(false);
    checkIfEmailEmpty();
    checkIfPasswordEmpty();
    checkIfEmailAndPasswordEmpty();
  };

  const checkIfEmailEmpty = () => {
    if (email === "") {
      setEmailEmpty(<p>Bitte Email eingeben</p>);
    } else {
      setEmailEmpty(<p style={{ display: "none" }}>Bitte Email eingeben</p>);
    }
  };

  const checkIfPasswordEmpty = () => {
    if (password === "") {
      setPasswordEmpty(<p>Bitte Kennwort eingeben</p>);
    } else {
      setPasswordEmpty(
        <p style={{ display: "none" }}>Bitte Kennwort eingeben</p>
      );
    }
  };

  const checkIfEmailAndPasswordEmpty = () => {
    if (email === "" && password === "") {
      setEmailAndPasswordEmpty(<p>Deine E-Mail und Kennwort eingeben</p>);
      setEmailEmpty(<p style={{ display: "none" }}>Bitte Email eingeben</p>);
      setPasswordEmpty(
        <p style={{ display: "none" }}>Bitte Kennwort eingeben</p>
      );
    }

    if (email !== "" || password !== "") {
      setEmailAndPasswordEmpty(
        <p style={{ display: "none" }}>Deine E-Mail und Kennwort eingeben</p>
      );
    }
  };

  const handleForgotPassword = () => {
    if (email !== "") {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setError("");
          setWrongPassword("");
          setAccountNotFound("");
          setPasswordResetSuccess(
            "Eine E-Mail zum Zurücksetzen des Passworts wurde gesendet. Bitte überprüfe deine E-Mails."
          );
          handleModalOpen();
        })
        .catch((error) => {
          console.error("Error sending password reset email: ", error.message);
          setError("Fehler beim Senden des Zurücksetzen-Links.");
        });
    } else {
      setError("Bitte gib deine E-Mail-Adresse ein.");
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <br />
          <div
            style={{
              textAlign: "center",
              position: "relative",
              bottom: "10px",
            }}
          >
            <Typography
              style={{ color: "rgb(0, 127, 255)", fontWeight: "bold" }}
              component="h4"
              variant="h4"
            >
              Nearwide
            </Typography>
            <br />
            <Typography style={{ color: "#000" }} component="p" variant="h6">
              Einfach und sicher lokal oder deutschlandweit einkaufen.
            </Typography>

            <br />
            {wrongPassword && (
              <h2>
                <Alert variant="filled" severity="error">
                  {wrongPassword}
                </Alert>
              </h2>
            )}
            {accountNotFound !== "" && (
              <>
                <Alert variant="filled" severity="error">
                  {accountNotFound}
                </Alert>
              </>
            )}

            <div
              style={{
                borderRadius: "10px",
                color: "#fff",
                backgroundColor: "red",
                fontSize: "20px",
              }}
            >
              {emailEmpty}
            </div>

            <div
              style={{
                borderRadius: "10px",
                color: "#fff",
                backgroundColor: "red",
                fontSize: "20px",
              }}
            >
              {passwordEmpty}
            </div>
            <div
              style={{
                borderRadius: "10px",
                color: "#fff",
                backgroundColor: "red",
                fontSize: "20px",
              }}
            >
              {emailAndPasswordEmpty}
            </div>
          </div>
          <br />
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              style={{ maxWidth: "100%" }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-Mail-Addresse"
              name="email"
              autoComplete="email"
              autoFocus
              ref={emailRef}
              onChange={(evt) => {
                setEmail(evt.target.value.replace(/\s/g, ""));
              }}
            />
            <TextField
              style={{ maxWidth: "100%" }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Kennwort"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              ref={passwordRef}
              onChange={(evt) => {
                setPassword(evt.target.value.replace(/\s/g, ""));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              style={{
                maxWidth: "100%",
                textTransform: "none",
                background: "rgb(0, 127, 255)",
              }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
              onClick={handleSubmit}
            >
              Anmelden
            </Button>

            <Link
              component={RouterLink}
              variant="body2"
              onClick={handleForgotPassword}
            >
              Passwort vergessen?
            </Link>

            {passwordResetSuccess && (
              <Typography variant="body2" color="textSecondary">
                {passwordResetSuccess}
              </Typography>
            )}

            <Modal
              open={modalOpen}
              onClose={handleModalClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div style={getModalStyle()} className={classes.modalPaper}>
                <IconButton
                  className={classes.closeBtn}
                  onClick={handleModalClose}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">
                  Eine E-Mail zum Zurücksetzen des Passworts wurde gesendet.
                  Bitte überprüfe deine E-Mails.
                </Typography>
              </div>
            </Modal>

            <Grid container>
              <Grid item>
                <p variant="body2">
                  {"Du hast noch keinen Account?  "}
                  <Link
                    href="/registrieren"
                    style={{ color: "rgb(0, 127, 255)" }}
                  >
                    Konto erstellen
                  </Link>
                </p>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Typography variant="body2" color="textSecondary" align="center">
                nearwide
                {" © "}
                <Link
                  color="inherit"
                  href="https://material-ui.com/"
                ></Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
