import * as React from "react";
import Box from "@mui/material/Box";
import { TextField, InputAdornment } from "@material-ui/core";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import "./Searchbox.css"

export default function Searchbox() {
    const navigate = useNavigate();
    return (
      <Box
      className="SearchBox"
        sx={{
          
       
          display:'grid',
          placeItems:'center',
          
        }}
        onClick={() => {
            navigate("/suche");
            
          }}
      >
        <TextField
         //style={{maxWidth:'100%', position:'relative', bottom:'30px'}}
          className="SearchTextField"
         fullWidth
          variant="outlined"
          label="Deutschlandweit suchen"
          helperText="Suche Lokal oder Deutschlandweit"
          id="fullWidth"
          InputProps={{
            startAdornment: (
              <InputAdornment position="">
                <IconButton color="primary" aria-label="add an alarm">
                  <AddLocationAltTwoToneIcon style={{fontSize:'30px'}}/>
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button color="primary">
                  <SearchSharpIcon
                    color="primary"
                    style={{
                      fontSize: "30px"
                    }}
                    //style={{ color: "rgb(25, 118, 210)" }}
                  />
                </Button>
              </InputAdornment>
            )
          }}
        />
      </Box>
    );
  }
  