import React, { useState, useEffect } from 'react';
import Vibrant from 'vibrant';
import './MainSlider.css'; // Create this file for custom styles











const ProductSlider = () => {
  const sliderImages = [
    "https://firebasestorage.googleapis.com/v0/b/vendorsbrand-productionmode.appspot.com/o/Homepage%20Slider%2F-10882666761308277092.jpg?alt=media&token=f8571818-2368-420a-8b3b-fea663ec9917",
    "https://firebasestorage.googleapis.com/v0/b/vendorsbrand-productionmode.appspot.com/o/Homepage%20Slider%2F14748298831403733729.jpg?alt=media&token=10f847fd-c72b-45ee-992a-8970e6cd5fe9",
    "https://firebasestorage.googleapis.com/v0/b/vendorsbrand-productionmode.appspot.com/o/Homepage%20Slider%2F-8220888841024349697.jpg?alt=media&token=c3f20780-f6f0-4fc8-9343-c85ed520c597",
  ];

  const [current, setCurrent] = useState(0);
  const [bgColor, setBgColor] = useState('rgba(0, 127, 255, 0.7)');
  const length = sliderImages.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(current => (current + 1) % length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup function to clear the interval when component unmounts
  }, [length]);

  useEffect(() => {
    const updateBackgroundColor = async () => {
      const image = new Image();
      image.crossOrigin = "Anonymous";
      image.src = sliderImages[current];
      
      image.onload = function() {
        const vibrant = new Vibrant(image);
        const swatch = vibrant.swatches()['Vibrant'];
        if (swatch) {
          setBgColor(swatch.rgb ? `rgba(${swatch.rgb[0]}, ${swatch.rgb[1]}, ${swatch.rgb[2]}, 0.7)` : 'rgba(0, 127, 255, 0.7)');
        }
      }
    };

    updateBackgroundColor();
  }, [current, sliderImages]);
 

  if (!Array.isArray(sliderImages) || sliderImages.length <= 0) {
    return null;
  }

  return (
    <div className="slider-container" style={{ background: `linear-gradient(to right, ${bgColor}, ${bgColor}), url(${sliderImages[current]}) no-repeat center center`, backgroundSize: 'cover' }}>
      <div className="slider">
        <div className="slider-wrapper" style={{ transform: `translateX(-${current * 100}%)` }}>
          {sliderImages.map((image, index) => (
            <div
              className={index === current ? 'slide active' : 'slide'}
              key={index}
            >
              {index === current && (
                <img src={image} alt={`Product ${index + 1}`} className="image" />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="slider-dots">
        {sliderImages.map((_, index) => (
          <span
            key={index}
            className={index === current ? 'dot active' : 'dot'}
            onClick={() => setCurrent(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ProductSlider;
