import React , { useState, useEffect } from "react";
import "./ProductCard.css";
import { useNavigate } from 'react-router-dom';
import {collection, getDocs } from "firebase/firestore";
import {firestore} from "../../firebaseConfig";
import FuzzySearch from 'react-fuzzy';
import fuzzyFilterFactory from 'react-fuzzy-filter'; 
import Fuse from 'fuse.js';
import "./CSSLoader.css";

function ProductCard(props) {
  const navigate = useNavigate();
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);
  const Search_Session = sessionStorage.getItem("SearchValue");
  const Location_Session = sessionStorage.getItem("Location");

  // console.log(Location_Session)

  useEffect(() => {
    const getProductsFromFirebase = [];
    const subscriber = firestore
      .collection("products")
      // .where("state", "==", "CA")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getProductsFromFirebase.push({
            ...doc.data(), //spread operator
            key: doc.id, // `id` given to us by Firebase
          });
        });
        setProduct(getProductsFromFirebase);
        setLoading(false);
      });

    // return cleanup function
    return () => subscriber();
  }, [loading]); // empty dependencies array => useEffect only called once

  if (loading) {
    return <div className="Loader">
   <div className="lds-hourglass"></div>
    
    </div>;
  }

  return (
    <>

<div className="grid-container searchPage">
  {Search_Session && product.length > 0 ? (
    <>
      {product.map((Product) => (
        Product.CanShippedTo.find(location => location.Ort === Location_Session) &&
        (Search_Session && Product.ProductName.toLowerCase().includes(Search_Session.toLowerCase().substring(0, 4)) ||
        Search_Session.toLowerCase().replace(/[\s-]/g, '').includes("tshirt")) && (
          <div key={Product.key} className="grid-item" onClick={() => { navigate(`Productid/${Product.ProductID}`); }}>
            <div className="product-img">
              <img src={Product.Card_imgURL} alt="Girl in a jacket" />
            </div>
            <div className="Product-details">
              <div className="Brand-Name">{Product.ProductName}</div>
              <div className="Product-Price">{Product.Product_Price}€</div>
            </div>
          </div>
        )
      ))}
     {Location_Session === "Deutschlandweit" && (
  <>
    {product.filter(Product => Product.GermanyWideShipping).map(Product => (
      (Search_Session && Product.ProductName.toLowerCase().includes(Search_Session.toLowerCase().substring(0, 4))
      ) && (
        <div key={Product.key} className="grid-item" onClick={() => { navigate(`Productid/${Product.ProductID}`); }}>
          <div className="product-img">
            <img src={Product.Card_imgURL} alt="Girl in a jacket" />
          </div>
          <div className="Product-details">
            <div className="Product-Name">{Product.ProductName}</div>
            <div className="Product-Price">{Product.Product_Price}€</div>
          </div>
        </div>
      )
    ))}
  </>
)}
    </>
  ) : (
    // <h1>Keine Ergebnisse gefunden!</h1>
    <></>
  )}
</div>

      
    </>
  );
  
}

export default ProductCard;
