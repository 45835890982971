import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { createMuiTheme } from "@material-ui/core/styles";
import { useNavigate } from 'react-router-dom';

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none",
      fontFamily: "Arial, serif"
    }
  }
});

export default function ButtonAppBar() {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={1} sx={{ bgcolor: "#fff", color: "#007FFF" }}>
        <Toolbar>
          <Typography
            style={{ fontFamily: "Arial, serif" }}
            variant="h5"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Nearwide
          </Typography>
          <Button theme={theme} color="inherit"
         onClick={() => {
          navigate("/anmelden");
        }}
          >
            Anmelden
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
