import React from 'react';
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import { useNavigate } from 'react-router-dom';
import './Orders.Table.css'
import { useState, useEffect } from "react";
import { firestore } from "../../firebaseConfig";
import firebase from "../../firebaseConfig";
import { Checkbox } from '@mui/material/Checkbox';

function OrdersTable() {
  const [loading, setLoading] = useState(true);
  const [ordersAvailable, setOrdersAvailable] = useState(true);
  const [product, setProduct] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const UserID = user.uid;
        const unsubscribe = firestore
          .collection("orders")
           .where("sellerId", "==", UserID) // Use UserID variable
          .onSnapshot((querySnapshot) => {
            const products = querySnapshot.docs.map(doc => ({id: doc.id, data: doc.data()}));
            setProduct(products);
            setOrdersAvailable(products.length > 0); // Überprüfen, ob Daten vorhanden sind
            setLoading(false);

            // console.log(product)
          });
        // return cleanup function
        return () => unsubscribe();
      } else {
        // User not logged in or has just logged out.
      }
    });  
  }, [loading]); // empty dependencies array => useEffect only called once

  return (
    <>
      <h2 className="h1Title">Die Kundenbestellungen</h2>
      <div style={{overflowX:"auto"}}>
        <table id="customers">
          <thead>
            <tr>
              <th>Bestellung Nr.</th>
              <th>Datum</th>
              <th>Kunde</th>
              <th>Gesamt</th>
            </tr>
          </thead>
          <tbody>
            {product.length > 0 ? (
              product.map((Product) => (
                <tr key={Product.key} onClick={() => navigate(`/Bestellung.id/${Product.data.orderId}`)}>
                  <td>{Product.data.orderNumber}</td>
                  <td>{Product.data.createdAt.toDate().toLocaleString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</td>
                  <td>{Product.data.firstandLastname}</td>
                  <td>€{Product.data.productPrice}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">
                 
                </td>
              </tr>
            )}
          </tbody>
        </table>

        
      {!ordersAvailable && (
        <h3 className="NoOrders"> Du hast noch keine Bestellungen erhalten! </h3>
      )}

      </div>
    </>
  )
}

export default OrdersTable;
