import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Impressum.css';
import Footer from '../../components/Footer/Footer.js';
import Header from '../../components/Header/Header.js';

const Impressum = () => {
  return (
    <div className="wrapper">
     <Header />
      <section>
        <div className="impressum-container">
          <div>
            <h2>Impressum – Über uns</h2>
            <hr />
            <div>
              <h3>Webseitenbetreiber:</h3>
              {/* <p>Abdulkader Sulaiman</p> */}
              <p>Nearwide DE</p>
            </div>
            <div>
              <h3>Informationen über das Unternehmen:</h3>
              <p>Einzelunternehmen</p>
            </div>
            <div>
              <h3>Adresse:</h3>
              <p>Mariannenstraße 43/1<br />74653 Ingelfingen</p>
            </div>
            <div>
              <h3>Kontakt:</h3>
              <p>
                E-Mail: kontakt@nearwide.de<br />
                Telefon: 015755437700
              </p>
            </div>
            <div>
              <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h3>
              {/* <p>Abdulkader Sulaiman</p> */}
              <p>Nearwide DE</p>
            </div>
            <div>
              <h3>Steuernummer:</h3>
              <p>-</p>
            </div>
            <div>
              <h3>Registergericht:</h3>
              <p>-</p>
            </div>
            <div>
              <h3>Registernummer:</h3>
              <p>-</p>
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default Impressum;
