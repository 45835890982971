import React, { useState, useEffect } from 'react';



export default function AccountPage() {

  return (
    <>
       <h1>Welcome to your Account </h1>
       
       Here you will find your Shippping adress 
    </>
  );
}
 
 