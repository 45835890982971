import React, { useState, useEffect } from "react";
import { firestore } from "../../firebaseConfig";
import firebase from "../../firebaseConfig";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";
import Button from "@material-ui/core/Button";
import "./Bestellen.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green, orange } from "@mui/material/colors";
import { serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header/Header.js";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const outerTheme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
  },
});

const innerTheme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
  },
});

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

function NewOrderForm(props) {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [productName, setProductName] = useState("");
  const [firstandLastname, setFirstandLastname] = useState("");
  const [ProductPrice, setProductPrice] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [hausNumber, setHausNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [cityName, setCityName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [houseNumberError, setHouseNumberError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [cityNameError, setCityNameError] = useState("");
  const [streetNameError, setStreetNameError] = useState("");
  const [submitState, setSubmitState] = useState(false);
  const [productImage, setProductImage] = useState([]);
  const [sellerId, setsellerId] = useState("");
  const navigate = useNavigate();

  const path = window.location.pathname;
  const pathParts = path.split("/");
  const productId = pathParts[pathParts.length - 1];
  const ProductURL = `https://nearwide.de/suche/Productid/${productId}`;

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const buyersRef = firebase.firestore().collection("Buyers");
        buyersRef
          .where("uid", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              setFirstandLastname(doc.data().VorundNachname);
              // console.log("==", doc.data());
            });
          })
          .catch((error) => {
            console.log("Error getting buyers: ", error);
          });
      }
    });
  }, []);


  useEffect(() => {
    const getProductsFromFirebase = [];
    const subscriber = firestore
      .collection("products")
      .where("ProductID", "==", productId)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getProductsFromFirebase.push({
            ...doc.data(),
            key: doc.id,
          });
          const data = doc.data();
          setProductName(data.ProductName);
          setsellerId(data.sellerId)
          setProductPrice(data.Product_Price);
          setProductImage(data.Product_imgs[0]);
        });
        setProduct(getProductsFromFirebase);
        setLoading(false);
      });

    return () => subscriber();
  }, [loading]);

  const sendNewInvoice = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const collectionRef = firestore.collection("invoices");
        collectionRef
          .add({
            ProductURL: ProductURL,
            UID: user.uid,
            Produktname: productName,
            fullName: firstandLastname,
            email: user.email,
            ProductPrice: ProductPrice,

            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(function(docRef) {
            // console.log("Document written with ID: ", docRef.id);
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });
      }
    });
  };

  const handleOrderSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    if (firstandLastname === "") {
      setNameError("Bitte geben Sie Ihren Namen ein.");
      isValid = false;
    } else {
      setNameError("");
    }

    if (PhoneNumber === "") {
      setPhoneError("Bitte geben Sie Ihre Telefonnummer ein.");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (hausNumber === "") {
      setHouseNumberError("Bitte geben Sie Ihre Hausnummer ein.");
      isValid = false;
    } else {
      setHouseNumberError("");
    }

    if (postalCode === "") {
      setPostalCodeError("Bitte geben Sie Ihre Postleitzahl ein.");
      isValid = false;
    } else {
      setPostalCodeError("");
    }

    if (cityName === "") {
      setCityNameError("Bitte geben Sie Ihre Stadt ein.");
      isValid = false;
    } else {
      setCityNameError("");
    }

    if (streetName === "") {
      setStreetNameError("Bitte geben Sie Ihre Straße ein.");
      isValid = false;
    } else {
      setStreetNameError("");
    }

    if (isValid) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          user.reload().then(async function() {
            const collectionRef = firestore.collection("orders");
            let orderNumber;
            let duplicateOrder; // Define duplicateOrder variable here

            // Ensure orderNumber is unique
            do {
              orderNumber = generateRandomNumber();
              duplicateOrder = await collectionRef
                .where("orderNumber", "==", orderNumber)
                .get();
              // Repeat the loop if the orderNumber is not unique
            } while (!duplicateOrder.empty);

            // Add the order to the Firestore collection
            collectionRef
              .add({
                orderNumber: orderNumber,
                ProductURL: ProductURL,
                sellerId: sellerId,
                uid:user.uid,
                email: user.email,
                Produktname: productName,
                productPrice: ProductPrice,
                firstandLastname: firstandLastname,
                PhoneNumber: PhoneNumber,
                houseNumber: hausNumber,
                postalCode: postalCode,
                cityName: cityName,
                streetName: streetName,
                productImage: productImage,
                createdAt: serverTimestamp(),
              })
              .then(function(docRef) {
                // Set the orderId to the document ID
                collectionRef.doc(docRef.id).update({
                  orderId: docRef.id,
                });
              })
              .catch(function(error) {
                console.error("Error adding document: ", error);
              });
          });
        }
      });
      sendNewInvoice();
      navigate("/Successful");
    }


    
  };

  const generateRandomNumber = () => {
    let randomNumber = Math.floor(Math.random() * 10000);
    let paddedNumber = randomNumber.toString().padStart(4, "0");
    return paddedNumber;
  };

  return (
    <>
      {product.length > 0 ? (
        product.map((Product) => (
          <div key={Product.key} className="mainContainer">
            <div className="contentContainer"></div>
            <br />
          </div>
        ))
      ) : (
        <h1></h1>
      )}
      <Header />
      <Box
        className="Box_Styles"
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "55ch" },
        }}
        noValidate
        autoComplete="off"
       
      >
        <Paper elevation={2} className="Paper"
         style={{marginTop: "-80px"}}
        >
          <div className="PersönlicheDaten">
            <h2>Persönliche Daten</h2>

            <TextField
              value={firstandLastname}
              onChange={(event) => setFirstandLastname(event.target.value)}
              className="TextField"
              id="Vor-und-Nachname"
              label="Vor- und Nachname"
              error={!!nameError}
              helperText={nameError}
            />

            <TextField
              onChange={(evt) => setPhoneNumber(evt.target.value)}
              className="TextField"
              id="Telefonnummer"
              label="Telefonnummer"
              error={!!phoneError}
              helperText={phoneError}
            />
          </div>

          <br />
          <Divider />
          <div className="Lieferadresse">
            <h2>Lieferadresse</h2>
            <TextField
              onChange={(evt) => setStreetName(evt.target.value)}
              className="TextField"
              id="outlined-Straße"
              label="Straße"
              error={!!streetNameError}
              helperText={streetNameError}
            />
            <TextField
              onChange={(evt) => setHausNumber(evt.target.value)}
              className="TextField"
              id="outlined-Hausnummer"
              label="Hausnummer"
              error={!!houseNumberError}
              helperText={houseNumberError}
            />
            <div>
              <TextField
                onChange={(evt) => setPostalCode(evt.target.value)}
                className="TextField"
                id="outlined-Postleitzahl"
                label="Postleitzahl"
                error={!!postalCodeError}
                helperText={postalCodeError}
              />
              <TextField
                onChange={(evt) => setCityName(evt.target.value)}
                className="TextField"
                id="outlined-Stadt"
                label="Stadt"
                error={!!cityNameError}
                helperText={cityNameError}
              />
            </div>
          </div>
        </Paper>

        <br />
        <Button
          className="btn-bestellen"
          color="primary"
          variant="contained"
          style={{
            background: "#007FFF",
            borderRadius: "100px",
            textTransform: "none",
            width: 460,
            height: 60,
            fontSize: "19px",
          }}
          onClick={handleOrderSubmit}
        >
          Jetzt bestellen
        </Button>
        <br />
      </Box>
    </>
  );
}

export default NewOrderForm;
