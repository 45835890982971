import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Searchbox from "../../components/Home_Searchbox/Searchbox";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebaseConfig";
import MainSlider from "../home/MainSlider.js";

function Home() {
  const navigate = useNavigate();
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProductsFromFirebase = [];
    const subscriber = firestore
      .collection("products")
      // .where("state", "==", "CA")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getProductsFromFirebase.push({
            ...doc.data(), //spread operator
            key: doc.id, // `id` given to us by Firebase
          });
        });
        setProduct(getProductsFromFirebase);
        setLoading(false);
      });

    // return cleanup function
    return () => subscriber();
  }, [loading]); // empty dependencies array => useEffect only called once

  if (loading) {
    return (
      <div className="Loader">
        <div className="lds-hourglass"></div>
      </div>
    );
  }

  return (
    <>
      {/* <Grid container className='CustomLeftMarginForHome '>
             <Sidebar />
             <HomePage /> 
         </Grid> */}

      <Header />
      <div className="Searchbox">
        <Searchbox />
      </div>

<MainSlider />
      <div className="grid-container">
        {product.length > 0 ? (
          <>
            {product
              // .filter((Product) => Product.GermanyWideShipping)
              .map((Product) => (
                <div
                  key={Product.key}
                  className="grid-item"
                  onClick={() => {
                    navigate(`suche/Productid/${Product.ProductID}`);
                  }}
                >
                  <div className="product-img">
                    <img src={Product.Card_imgURL} alt="Girl in a jacket" />
                  </div>
                  <div className="Product-details">
                    <div className="Product-Name">{Product.ProductName}</div>
                    <div className="Product-Price" style={{textAlign:'center'}}>  
                    <del>{Product.Oldprice}</del>
                    &nbsp;   
                    {Product.Product_Price}<span style={{ fontSize: '0.7em', padding:"2px"}}>&euro;</span>  
                    <br/>
                    <span style={{padding:'10px', fontWeight:'bold', color:'red'}}>{Product.discount}</span>
                      <br/>
                    </div>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <h1>Keine Ergebnisse gefunden!</h1>
        )}
      </div>

      {/* <div style={{ marginTop:'-45px'}} className="containerList"> 
 <HorizonList />
 </div> */}

      <div style={{ textAlign: "center" }} className="HomeContainer">
        {/* <Slider /> */}
      </div>

      <Footer />
    </>
  );
}

export default Home;
