import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
//import firebase from "../../firebaseConfig";
import Searchbox from "../../components/Home_Searchbox/Searchbox";
import firebase from "firebase/compat/app"; //v9
import Slider from "../../components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
// import "./Elektronik.css";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Button} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";


function LocalShopping() {
  const navigate = useNavigate();
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProductsFromFirebase = [];
    const subscriber = firestore
      .collection("products")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const productData = {
            ...doc.data(),
            key: doc.id,
          };
  
          // Check if CanShippedTo array exists and has a map with Ort property equal to "Heilbronn"
          if (
            productData.CanShippedTo &&
            Array.isArray(productData.CanShippedTo) &&
            productData.CanShippedTo.some((shipment) => shipment.Ort === "Heilbronn")
          ) {
            getProductsFromFirebase.push(productData);
          }
        });
  
        setProduct(getProductsFromFirebase);
        setLoading(false);
        console.log("Products from Firebase:", getProductsFromFirebase);
      });
  
    // return cleanup function
    return () => subscriber();
  }, [loading]);
  


  if (loading) {
    return (
      <div className="Loader">
        <div className="lds-hourglass"></div>
      </div>
    );
  }

  return (
    <>
      {/* <Grid container className='CustomLeftMarginForHome '>
             <Sidebar />
             <HomePage /> 
         </Grid> */}

      <Header />
      <div style={{textAlign:'center', position:'relative',  bottom: '1px'}}>
        {/* <Searchbox /> */}

       
        <h2 style={{textAlign: 'center',  fontFamily: "Arial, sans-serif", 
        color: "rgba(0, 0, 0, 0.72)"}}
        
        >Lokal geht schneller.</h2>
      
      <br />

      <p
        className="categorieTitle"
        style={{
          position: "relative",
          left: "0.7%",
          fontFamily: "Arial, sans-serif",
          color: "rgba(0, 0, 0, 0.72)",
          fontSize: "1.1rem",
          textDecoration: "none",
          position:'relative',  
          bottom: '35px'
        }}
      >
        <LocationOnIcon /> Heilbronn
      </p>
      </div>
    
    

      <div className="grid-container">
        {product.length > 0 ? (
          <>
            {product
              .filter((Product) => Product.GermanyWideShipping)
              .map((Product) => (
                <div
                  key={Product.key}
                  className="grid-item"
                  onClick={() => {
                    navigate(`suche/Productid/${Product.ProductID}`);
                  }}
                >
                  <div className="product-img">
                    <img src={Product.Card_imgURL} alt="Girl in a jacket" />
                  </div>
                  <div className="Product-details">
                    <div className="Product-Name">{Product.ProductName}</div>
                    <div className="Product-Price">
                      {Product.Product_Price}€
                    </div>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <h1>Keine Ergebnisse gefunden!</h1>
        )}
      </div>

      <div style={{ textAlign: "center" }} className="HomeContainer"></div>

      <Footer />
    </>
  );
}

export default LocalShopping;
