import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { FixedSizeList } from "react-window";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import List from "@mui/material/List";
import "./uploadNew_Product.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { setDoc, doc } from "firebase/firestore";
import {
  ref,
  uploadString,
  getDownloadURL,
  getStorage,
} from "firebase/storage";
import { ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { firestore, storage, db, auth } from "../../firebaseConfig";
import Stack from "@mui/material/Stack";
import ImageIcon from "@mui/icons-material/Image";
import { FormHelperText } from "@mui/material";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import Alert from "@mui/material/Alert";
import "./animatedLoader.css";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Locations = [
  { Ort: "Heilbronn",    Plz: 74072, Bundesland: "Baden-Württemberg" },
  { Ort: "Öhringen",     Plz: 74613, Bundesland: "Baden-Württemberg" },
  { Ort: "Ingelfingen",  Plz: 74653, Bundesland: "Baden-Württemberg" },
  { Ort: "Künzelsau",    Plz: 74653, Bundesland: "Baden-Württemberg" },
];




function RenderRow(props) {
  const { index, style } = props;

  const [slectedValue, setSelectedValue] = useState("");
  const [RestaurantsPath, setRestaurantsPath] = useState("");
  const [SelectedPath, setSelectedPath] = useState("");
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const [prodName, setprodName] = useState("");
  const [prodPreis, setprodPreis] = useState("");
  const [prodBeschreibung, setprodBeschreibung] = useState("");
  const [shipTime, setShipTime] = useState("");
  const [functionIsRunning, setfunctionIsRunning] = useState(false);
  const [productIsAdded, setproductIsAdded] = useState(false);
  const [uploadLoading, setuploadLoading] = useState(false);
  const [isuploaded, setisuploaded] = useState(false);
  const auth = getAuth();

  const [localShippingLocations, setlocalShippingLocations] = useState([]);
  const [GermanyWideShipping, setGermanyWideShipping] = useState(false);
  const sellersCollection = firestore.collection("Sellers");
  const [locationsName, setLocationsName] = useState([]);
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const [BrandName, setBrandName] = useState('');
  const [error, setError] = useState(false);

  const handleselect_LocationChange = (event) => {
    const { value } = event.target;
    setLocationsName(value);
    setIsLocationSelected(value.length > 0);
  };

  const handleSelectLocationChange = (event) => {
    const { value } = event.target;
    setLocationsName(value);
    // Reset error state when location is changed
    setError(false);
  };
  const classes = useStyles();

  const lieferungszeiten = [
    "1-2 Std",
    "3-4 Std",
    "5-6 Std",
    "7-8 Std",
    "8-9 Std",
    "1 Werktage",
  ];

  // Get Seller local Shipping Locations - To save them on upload new Products
  useEffect(() => {
    const sellersCollection = firestore.collection("Sellers");

    auth.onAuthStateChanged((user) => {
      if (user) {
        const userUid = user.uid;

        sellersCollection
          .where("uid", "==", userUid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const sellerData = doc.data();
              setlocalShippingLocations(sellerData.localShipping);
              setGermanyWideShipping(sellerData.GermanyWideShipping);
              setBrandName(sellerData.BrandName)
            });
          })
          .catch((error) => {
            console.error("Error retrieving Sellers collection:", error);
          });
      }
    });
  }, []); // Empty dependency array to run the effect only once

  //   console.log('Seller data:', localShippingLocations);

  // console.log("Wide Shipping... ", GermanyWideShipping)

  const handleChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };

  const handleUpload = () => {
    const promises = [];
    images.map((image) => {
      const sotrageRef = ref(storage, `ProductsImages/${image.name}`);
      const uploadTask = uploadBytesResumable(sotrageRef, image);

      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const storage = getStorage();
          const starsRef = ref(storage, `ProductsImages/${image.name}`);
          await getDownloadURL(starsRef).then((urls) => {
            setUrls((prevState) => [...prevState, urls]);
            // sessionStorage.getItem(urls);
          });
        }
      );
    });
    Promise.all(promises)
      .then(setfunctionIsRunning(true))
      .catch((err) => console.log(err));
  };

  function addNewProduct() {
    // Get a reference to the collection

    const collection = firestore.collection("products");
    setTimeout(() => {
      var ProductImages = JSON.parse(sessionStorage.getItem("product_Images"));
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          // Add a document to the collection with the file URL
          collection
            .add({
              Product_imgs: ProductImages,
              ProductCategory: Path,
              CanShippedTo: locationsName,
               GermanyWideShipping: GermanyWideShipping,
              ProductName: prodName,
              Product_Price: prodPreis,
              localShippingTime: shipTime,
              Product_desc: prodBeschreibung,
              BrandName:BrandName,
              Card_imgURL: ProductImages[0],
            })
            .then((docRef) => {
              docRef.set(
                {
                  ProductID: docRef.id,
                  sellerId: user.uid,
                  // "otherField": "otherValue"
                },
                { merge: true }
              );
              console.log("Document added with ID: ", docRef.id);
              setproductIsAdded(true);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        } else {
          // User is signed out
          // ...
        }
      });
    }, 2000);
  }

  if (functionIsRunning == true) {
    window.sessionStorage.setItem("product_Images", JSON.stringify(urls));
    setTimeout(() => {
      document.location.reload();
    }, 3000);
  }

  if (functionIsRunning == true) {
    setTimeout(() => {
      setuploadLoading(true);
    }, 300);
  }

  if (functionIsRunning == true) {
    setTimeout(() => {
      setisuploaded(true);
    }, 400);
  }

  function handleClick() {
    handleUpload();
    addNewProduct();
  }

  var Path = "";
  if (slectedValue == "") {
    var ProductCategorie = [
      "Elektronik",
      "Geschenke und Accessoires",
      "Büro-und Schulwerkzeuge",
      "Autoteile-und Accessories",
      // "Restaurants",
      // "Lebensmittel",
      // "Kleidung",
      "Schuhe",
      "Kinderspielzeuge",
      "Werkzeuge",
    ];
  }

  if (slectedValue == "Restaurants") {
    var ProductCategorie = [
      "Pizza",
      "Spaghetti",
      "Pasta",
      "Döner",
      "Döner Kebab",
      "Kebab",
      "Döner Speise",
      "Nachtisch",
      "Süsigkeiten",
      "Getränke",
      "Etwas anderes",
    ];
    var Path = "Restaurants ";
  }

  if (slectedValue == "Pizza") {
    var ProductCategorie = [
      "Margherita",
      "Salami",
      "Vier Jahreszeiten",
      "Vier Käse",
      "Kinderpizza",
      "Pizza",
    ];
    var Path = "Restaurants / " + slectedValue;
  }

  if (slectedValue == "Spaghetti") {
    var ProductCategorie = [slectedValue];
    var Path = "Restaurants / " + slectedValue;
  }
  if (slectedValue == "Pasta") {
    var ProductCategorie = [slectedValue];
    var Path = "Restaurants / " + slectedValue;
  }
  if (slectedValue == "Döner") {
    var ProductCategorie = [slectedValue];
    var Path = "Restaurants / " + slectedValue;
  }

  if (slectedValue == "Döner Kebab") {
    var ProductCategorie = [slectedValue];
    var Path = "Restaurants / " + slectedValue;
  }

  if (slectedValue == "Kebab") {
    var ProductCategorie = [slectedValue];
    var Path = "Restaurants / " + slectedValue;
  }

  if (slectedValue == "Döner Speise") {
    var ProductCategorie = [slectedValue];
    var Path = "Restaurants / " + slectedValue;
  }
  if (slectedValue == "Nachtisch") {
    var ProductCategorie = [slectedValue];
    var Path = "Restaurants / " + slectedValue;
  }

  if (slectedValue == "Süsigkeiten") {
    var ProductCategorie = [slectedValue];
    var Path = "Restaurants / " + slectedValue;
  }

  if (slectedValue == "Getränke") {
    var ProductCategorie = [slectedValue];
    var Path = "Restaurants / " + slectedValue;
  }

  if (
    slectedValue == "Margherita" ||
    slectedValue == "Salami" ||
    slectedValue == "Vier Jahreszeiten" ||
    slectedValue == "Vier Käse" ||
    slectedValue == "Kinderpizza" ||
    slectedValue == " Pizza"
  ) {
    var ProductCategorie = [
      "Margherita",
      "Salami",
      "Vier Jahreszeiten",
      "Vier Käse",
      "Kinderpizza",
      "Pizza",
    ];
    var Path = "Restaurants / Pizza /" + slectedValue;
  }

  if (slectedValue == "Lebensmittel") {
    var ProductCategorie = [
      "Obst",
      "Gemüse",
      "Fleisch",
      "Brot",
      "Getränke",
      "Gewürze, Würzmittel & Aromen",
      "Getreide & Getreideerzeugnisse",
      "Öle & Fette",
      "Nüsse",
      "Honig",
      "Milch, Milcherzeugnisse & Käse",
      "Pilze & Pilzerzeugnisse",
      "Kräuter",
      "Etwas anderes",
    ];
    var Path = "Lebensmittel ";
  }

  if (slectedValue == "Obst") {
    var ProductCategorie = [
      "Äpfeln",
      "Bananen",
      "Orange",
      "Birnen",
      "Quitten",
      "Aprikosen",
      "Pfirsich",
      "Etwas anderes",
    ];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (
    slectedValue == "Äpfeln" ||
    slectedValue == "Bananen" ||
    slectedValue == "Orange" ||
    slectedValue == "Birnen" ||
    slectedValue == "Quitten" ||
    slectedValue == "Aprikosen" ||
    slectedValue == "Pfirsich" ||
    slectedValue == "Etwas anderes"
  ) {
    var ProductCategorie = [slectedValue];
    var Path = "Lebensmittel / Obst /" + slectedValue;
  }

  if (slectedValue == "Gemüse") {
    var ProductCategorie = ["Gemüse"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Fleisch") {
    var ProductCategorie = ["Fleisch"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Brot") {
    var ProductCategorie = ["Brot"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Getränke") {
    var ProductCategorie = ["Getränke"];
    var Path = "Lebensmittel / " + slectedValue;
  }
  if (slectedValue == "Gewürze, Würzmittel & Aromen") {
    var ProductCategorie = ["Gewürze, Würzmittel & Aromen"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Getreide & Getreideerzeugnisse") {
    var ProductCategorie = ["Getreide & Getreideerzeugnisse"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Getreide & Getreideerzeugnisse") {
    var ProductCategorie = ["Getreide & Getreideerzeugnisse"];
    var Path = "Lebensmittel / " + slectedValue;
  }
  if (slectedValue == "Öle & Fette") {
    var ProductCategorie = ["Öle & Fette"];
    var Path = "Lebensmittel / " + slectedValue;
  }
  if (slectedValue == "Nüsse") {
    var ProductCategorie = ["Nüsse"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Honig") {
    var ProductCategorie = ["Honig"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Milch, Milcherzeugnisse & Käse") {
    var ProductCategorie = ["Milch, Milcherzeugnisse & Käse"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Pilze & Pilzerzeugnisse") {
    var ProductCategorie = ["Pilze & Pilzerzeugnisse"];
    var Path = "Lebensmittel / " + slectedValue;
  }
  if (slectedValue == "Kräuter") {
    var ProductCategorie = ["Kräuter"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Etwas anderes") {
    var ProductCategorie = ["Etwas anderes"];
    var Path = "Lebensmittel / " + slectedValue;
  }

  if (slectedValue == "Elektronik") {
    var ProductCategorie = [
      "Laptops",
      "Computers",
      "Handys",
      "Spiele",
      "Etwas anderes",
    ];
    var Path = "Elektronik / ";
  }

  if (
    slectedValue == "Laptops" ||
    slectedValue == "Computers" ||
    slectedValue == "Handy"
  ) {
    var ProductCategorie = [slectedValue];
    var Path = "Elektronik / " + slectedValue;
  }

  if (
    slectedValue == "Computers" ||
    slectedValue == "Handys" ||
    slectedValue == "Spiele"
  ) {
    var ProductCategorie = [slectedValue];
    var Path = "Elektronik / " + slectedValue;
  }

  if (slectedValue == "Kleidung") {
    var ProductCategorie = [
      "Kinderkleidung",
      "Männerkleidung",
      "Frauenkleidung",
    ];
    var Path = "Kleidung ";
  }

  if (slectedValue == "Frauenkleidung") {
    var ProductCategorie = [slectedValue];
    var Path = "Kleidung / " + slectedValue;
  }

  if (slectedValue == "Männerkleidung") {
    var ProductCategorie = [slectedValue];
    var Path = "Kleidung / " + slectedValue;
  }

  if (slectedValue == "Kinderkleidung") {
    var ProductCategorie = [slectedValue];
    var Path = "Kleidung / " + slectedValue;
  }

  if (slectedValue == "Schuhe") {
    var ProductCategorie = [slectedValue];
    var Path = "Schuhe / ";
  }

  if (slectedValue == "Kinderspielzeuge") {
    var ProductCategorie = [slectedValue];
    var Path = "Kinderspielzeuge / ";
  }

  if (slectedValue == "Büro-und Schulwerkzeuge") {
    var ProductCategorie = [slectedValue];
    var Path = "Büro-und Schulwerkzeuge / ";
  }

  if (slectedValue == "Werkzeuge") {
    var ProductCategorie = [slectedValue];
    var Path = "Werkzeuge / ";
  }

  if (slectedValue == "Autoteile-und Accessories") {
    var ProductCategorie = [slectedValue];
    var Path = "Autoteile-und Accessories / ";
  }

  if (ProductCategorie == null) {
    ProductCategorie = ["NO"];
    Path = "";
  }

  // if(slectedValue == "") {
  //   console.log("NO! Selected Value")
  // }
  // if(slectedValue != "") {
  //   console.log("YES! Selected Value")
  // }

  return (
    <>
      {isuploaded == true && (
        <h2>
          <Alert onClose={() => {}}>Bitte warten Sie kurz.</Alert>
        </h2>
      )}

      {uploadLoading == true && (
        <h2>
          <div class="lds-dual-ring"></div>
        </h2>
      )}

      <div>
        <br />
        <br />

        <div>
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            onClick={({ target }) => setSelectedValue("")}
          >
            Zurück
          </Button>
        </div>
      </div>

      <h2>{Path}</h2>
      <br />
      <br />
      {slectedValue != "" && (
        <div className="UploadImgs">
          <Button
            id="files"
            color="primary"
            aria-label="upload picture"
            component="label"
            style={{ textTransform: "none" }}
          >
            <input
              hidden
              accept="image/*"
              type="file"
              multiple
              onChange={handleChange}
            />
            Produktbilder auswählen &nbsp;
            <ImageIcon style={{ fontSize: "30px" }} />
          </Button>
          {images == "" && (
            <h2>
              <FormHelperText
                style={{ position: "relative", bottom: "13px", color: "red" }}
              >
                * Produktbilder hochladen
              </FormHelperText>
            </h2>
          )}

          <Box
            className="TextFieldBox"
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "42ch" },
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                label="Produktname"
                id="outlined-size-normal"
                onChange={(e) => setprodName(e.target.value)}
                error={prodName === ""}
                helperText={prodName === "" ? "Produktname eingeben" : ""}
              />

              <TextField
                label="Produktpreis in Euro"
                id="outlined-size-normal"
                onChange={(e) => setprodPreis(e.target.value)}
                error={prodPreis === ""}
                helperText={prodPreis === "" ? "Preis eingeben" : ""}
                type="number"
                className={classes.input}
              />
              <TextField
                label="Produktbeschreibung"
                id="outlined-size-normal"
                placeholder="Geben Sie eine spannende Produktbeschreibung ein."
                multiline
                rows={3}
                maxRows={4}
                onChange={(e) => setprodBeschreibung(e.target.value)}
                error={prodBeschreibung === ""}
                helperText={
                  prodBeschreibung === "" ? "Beschreibung eingeben" : ""
                }
              />

              <div>
                <FormControl sx={{ m: 1, width: 368 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Wollen Sie lokal verkaufen? Orte auswählen{" "}
                  </InputLabel>
                  <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={locationsName}
        onChange={handleSelectLocationChange}
        input={<OutlinedInput label="Wollen Sie lokal verkaufen? Orte auswählen" />}
        renderValue={(selected) => (
          <div>
            {selected.map((locationName) => (
              <div key={locationName.Ort}>
                {locationName.Ort}, {locationName.Plz}, {locationName.Bundesland}
              </div>
            ))}
          </div>
        )}
      >
        {Locations.map((location) => (
          <MenuItem key={location.Ort} value={location}>
            <Checkbox checked={locationsName.some((item) => item.Ort === location.Ort)} />
            <ListItemText primary={location.Ort} />
          </MenuItem>
        ))}
      </Select>
                </FormControl>
              </div>
              <br />
              <TextField
                select
                label="Lieferungszeit für die Lokale Bestellung"
                id="outlined-size-normal"
                value={shipTime}
                onChange={(e) => setShipTime(e.target.value)}
                error={locationsName.length > 0 && shipTime === ""}
                helperText={
                  locationsName.length > 0 && shipTime === ""
                    ? "Lieferungszeit auswählen"
                    : ""
                }
              >
                {lieferungszeiten.map((zeit) => (
                  <MenuItem key={zeit} value={zeit}>
                    {zeit}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <br />
            <br />
            <Button
              disabled={
                // shipTime === ""

                functionIsRunning ||
                prodName === "" ||
                prodPreis === "" ||
                prodBeschreibung === "" ||
                images.length === 0 // Check if no images have been selected
              }
              onClick={handleClick}
              className="btn-bestellen"
              color="primary"
              variant="contained"
              style={{
                marginLeft: "10px",
                borderRadius: "100px",
                textTransform: "none",
                width: 290,
                height: 40,
                fontSize: "19px",
              }}
            >
              Produkt hochladen
            </Button>

            {/* <Button disabled={prodName == ""}  onClick={addNewProduct} className="btn-bestellen" color="primary" variant="contained" style={{marginLeft:"10px", borderRadius:'100px', textTransform: "none", width: 290, height: 40, fontSize:'19px' }}>
      Produkt hochladen 2
      </Button> */}
            <br />
            <br />
          </Box>

          {/* <button onClick={handleUpload}>hochladen</button> */}

          {urls.map((url, i) => (
            <div key={i}>
              <a href={url} target="_blank">
                {url}
              </a>
            </div>
          ))}
          <br />
          {urls.map((url, i) => (
            <img
              key={i}
              style={{ width: "500px" }}
              src={url || "http://via.placeholder.com/300"}
              alt="firebase-image"
            />
          ))}
        </div>
      )}

      {/* {PizzaPath} */}
      {/* {["A", "B"].map((value) => (    
    <ListItem
   className="ListItems"
      style={style}
      key={value}
      component="div"
      disablePadding
      secondaryAction={
        <IconButton aria-label="ArrowForward">
          <ArrowForwardIosIcon />
        </IconButton>
      }
      key={value}
      disableGutters
    >
      <ListItemButton>
        <ListItemText primary={`${value}`} />
      </ListItemButton>
      
    </ListItem>
 ))}
     */}

      {slectedValue == "" && (
        <h2>
          <List style={{ maxHeight: "250px", overflow: "auto" }}>
            {ProductCategorie.map((value) => (
              <ListItem
                // onClick={navigate('/Produkte/kategorien/hochladen')}
                className="ListItems"
                // style={style}
                key={value}
                disableGutters
                secondaryAction={
                  <IconButton aria-label="ArrowForward">
                    <ArrowForwardIosIcon />
                  </IconButton>
                }
                onClick={({ target }) => setSelectedValue(target.textContent)}
                //   onClick={() => {
                //     navigate(value);

                //   }}
              >
                <ListItemButton>
                  <ListItemText primary={`${value}`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </h2>
      )}
      {/*  */}

      {slectedValue != "" && (
        <h2>
          <List
            style={{ maxHeight: "250px", overflow: "auto", display: "none" }}
          >
            {ProductCategorie.map((value) => (
              <ListItem
                // onClick={navigate('/Produkte/kategorien/hochladen')}
                className="ListItems"
                // style={style}
                key={value}
                disableGutters
                secondaryAction={
                  <IconButton aria-label="ArrowForward">
                    <ArrowForwardIosIcon />
                  </IconButton>
                }
                onClick={({ target }) => setSelectedValue(target.textContent)}
                //   onClick={() => {
                //     navigate(value);

                //   }}
              >
                <ListItemButton>
                  <ListItemText primary={`${value}`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </h2>
      )}
    </>
  );
}

export default RenderRow;
function VirtualizedList(props) {
  return (
    <>
      {/* <div >
       <Box
   
      className="SearchboxContainer"
      sx={{
        //width: 800,
       
      }}
    >
    <TextField fullWidth className="Searchbox" label="fullWidth" id="fullWidth" />
    </Box>
    </div> */}
      {/* <h2>{props.Path}</h2> */}
      <br />

      <Box
        className="ListContainer"
        sx={{
          width: "100%",
          height: 430,
          // maxWidth: 360,
          // bgcolor: "background.paper",
        }}
      >
        {/* <FixedSizeList
       style={{overflowY: "hidden"}}
        height={430}
        // width={360}
        itemSize={56}
        itemCount={1}
        overscanCount={1}
      > */}
        {RenderRow}
        {/* </FixedSizeList> */}
      </Box>
    </>
  );
}
