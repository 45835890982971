import React from 'react';
import "./Footer.css";
const Footer = () => (
    <>
 
<div  >
{/* <p>Nearwide &copy; 2022</p> */}


         <footer className="footer">
        <div className="footer-container">
          <div className="footer-section">
            <h3>Nearwide</h3>
            <ul>
              <li>
                <a href="/über-uns">Über Nearwide</a>
              </li>
              <li>
                <a href="/lokale.Zustellung">Lokale Zustellung</a>
              </li>
              <li>
                <a href="/UnderMaintenance">FAQs</a>
              </li>
              {/* <li>
                <a href="/UnderMaintenance">Kontakt</a>
              </li> */}
            </ul>
          </div>
          {/* <div className="footer-section">
            <h3>Hilfe</h3>
            <ul>
              <li>
                <a href="#">Versand & Lieferung</a>
              </li>
              <li>
                <a href="#">Rücksendungen & Ersatz</a>
              </li>
              <li>
                <a href="#">Konto verwalten</a>
              </li>
            </ul>
          </div> */}
          <div className="footer-section">
            <h3>Verbindung halten</h3>
            <ul>
              {/* <li>
                <a href="#">Newsletter abonnieren</a>
              </li> */}
              <li>
                <a href="https://www.instagram.com/nearwide.de?igsh=bzIyYXBkNjFkNnF3">Folge uns auf Instagram</a>
              </li>
              {/* <li>
                <a href="#">Teilnahme am Partnerprogramm</a>
              </li> */}
            </ul>
          </div>
          <div className="footer-section">
            <h3>Rechtliches</h3>
            <ul>
            <li>
                <a href="/Impressum">Impressum</a>
              </li>
              <li>
                <a href="/Datenschutzrichtlinie">Datenschutzrichtlinie</a>
              </li>
              <li>
                <a href="/AGB">Allgemeine Geschäftsbedingungen</a>
              </li>
              {/* <li>
                <a href="#">Barrierefreiheit</a>
              </li> */}
            </ul>
          </div>
        </div>
      </footer>  


</div>
    </>
  );

  export default Footer;