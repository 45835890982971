import React, { useEffect } from 'react';
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import SignupHeader from './SignupHeader';
import Paper from '@mui/material/Paper';
import "./Signup.css"
import Footer from '../../components/Footer/Footer'
import NativeSelect from '@mui/material/NativeSelect';
import { useNavigate } from 'react-router-dom';
import {getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { sendSignInLinkToEmail } from "firebase/auth";
import { linkWithCredential, EmailAuthProvider } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useState } from "react";
import ReactDOM from 'react-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import { firestore } from "../../firebaseConfig"
import { sendEmailVerification } from "firebase/auth";
import firebase from 'firebase/compat/app'; //v9
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";
import {InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {fetchSignInMethodsForEmail } from "firebase/auth";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const names = ["Kleidung", "Gastronomie", "Mode", "Elektronik"];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const steps = ["Kontoerstellung", "Verifizierung"];

const useHelperTextStyles = makeStyles(() => ({
	root: {
		color: "red"
	}
}));


function Signup(props, str) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [accounttype, setaccounttype] = React.useState("");
    const [selected, hasError] = React.useState("");
    const [VorundNachname, setvorundNachname] = React.useState("");
    const [GeschäftsKonto_VorundNachname, setGeschäftsKonto_VorundNachname] = React.useState("");
    const [GeschäftsKonto_Email, setGeschäftsKonto_Email] = React.useState("");
    const [GeschäftsKonto_Mobiltelefonnummer, setGeschäftsKonto_Mobiltelefonnummer] = React.useState("");
    const [GeschäftsKonto_password, setGeschäftsKonto_Password] = React.useState("");
    const [GeschäftsKonto_RepPassword, setGeschäftsKonto_RepPassword] = React.useState("");
    const [Geschäftsname, setGeschäftsname] = React.useState("");
    const [Märkte, setMärkte] = React.useState([]);
    const [Kontoinhaber, setkontoinhaber] = React.useState("");
    const [IBAN, setIBAN] = React.useState("");
    const [ShippingTo, setShippingTo] = React.useState([]);
    const [SwiftCode, setSwiftCode] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [RepPassword, setRepPassword] = React.useState("");
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [isEmailinUse, setisEmailinUse] = React.useState(false);
    const navigate = useNavigate();
    const auth = getAuth();


    if (VorundNachname == "" || GeschäftsKonto_VorundNachname == "") {
      var helperTextMess = (
        <p style={{ color: "red" }}>Vor- und Nachname eingeben*</p>
      );
    }

    if (VorundNachname != "" || GeschäftsKonto_VorundNachname !== "") {
      var helperTextMess = (
        <p style={{ display: "none" }}>vor- und Nachname eingeben</p>
      );
    }

    
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (emailRegex.test(Email)) {
    // setIsValidEmail(true);
    console.log("Email is valid")
  } 
  
  else {
    // setIsValidEmail(false);
    var NotValid = false;
  var EmailValdition_helperTextMess = (
      <p style={{ color: "red" }}>Bitte eine gültige E-Mail-Adresse eingeben*</p>
    );
    console.log("Email is NOOOT valid")
  
  }
 

    if (Email == "" ) {
      var Email_helperTextMess = (
        <p style={{ color: "red" }}>E-Mail Adresse eingeben*</p>
      );
    }

    if (Email != "") {
      var Email_helperTextMess = (
        <p style={{ display: "none" }}>E-Mail Adresse eingeben</p>
      );
    }

    if (password == "") {
      var Password_helperTextMess = (
        <p style={{ color: "red" }}>Kennwort eingeben*</p>
      );
    }

    if (password != "") {
      var Password_helperTextMess = (
        <p style={{ display: "none" }}>Kennwort eingeben</p>
      );
    }

  

// After complete the steps go to login page
if(activeStep == 3) {
  navigate("/anmelden");
}

    const validationSchema = yup.object({
      email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
    });

 
    const formik = useFormik({
      initialValues: {
        email: '',
        password: 'foobar',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        alert(JSON.stringify(values, null, 2));
      }});


      const handleShippingTo = (e, value) => {
        setShippingTo(value)
      };

    

      function CreateNewBuyerAccount() {
        // Check if the email already exists
        fetchSignInMethodsForEmail(auth, Email)
          .then((methods) => {
            if (methods.length === 0) {
              // Email doesn't exist, proceed with account creation
              createUserWithEmailAndPassword(auth, Email, password)
                .then((userCredential) => {
                  // Account created successfully
                  const user = userCredential.user;
                  // Send email verification
                  sendEmailVerification(auth.currentUser)
                    .then(() => {
                      // Email verification sent
                      // console.log("Email verification sent");
                    })
                    .catch((error) => {
                      console.error("Error sending email verification:", error);
                    });
      
                  // Add user data to Firestore
                  const uid = user.uid;
                  firestore.collection("Buyers").doc(uid).set({
                    VorundNachname: VorundNachname,
                    Email: user.email,
                    uid: uid
                  })
                  .then(() => {
                    console.log("Document successfully written to Firestore!");
                  })
                  .catch((error) => {
                    console.error("Error writing document to Firestore: ", error);
                  });
                })
                .catch((error) => {
                  // Error creating account
                  console.error("Error creating account:", error);
                });
            } else {
              // Email already exists
              // console.log("Email already exists");
              setisEmailinUse(true)
              // Handle the case where the email already exists (e.g., show error message)
            }
          })
          .catch((error) => {
            // Error fetching sign-in methods
            console.error("Error fetching sign-in methods:", error);
          });
      
      
}

if(activeStep == 1) {
  CreateNewBuyerAccount()
}

    const filterOptions = createFilterOptions({
      matchFrom: "start"
    });

  
    const handleMärkteChange = (event) => {
      const {
        target: { value }
      } = event;
      setMärkte(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    };

    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleAccounttypeChange = (event) => {
      setaccounttype(event.target.value);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
       
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };

   
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
 
  
    const handleReset = () => {
      setActiveStep(0);
    };
    
    const handleSendSelection = (event) => {
      console.log("value - ", event.target.value);
    };

    const [name, setName] = useState();
    const {
      register,
      handleSubmit,
      reset,
      watch,
      formState: { errors }
    } = useForm();
    const { ref, ...rest } = register("", { required: true });
  
    const currentName = watch("name");
  
// After complete the steps go to login page
if(activeStep == 2) {
  navigate("/anmelden");
}
    function onSubmit(data) {
      setName(data.name);
      reset();
    }

    const helperTextStyles = useHelperTextStyles();
    return (
        <>
        <SignupHeader />
      
        <Box className='stepperContainer'>
          
        <h2 className="signupTitle">Neues Nearwide Konto erstellen</h2>
        <Paper className="Paper"  elevation={0} > 
          <Stepper activeStep={activeStep} className="Stepper">
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
    
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <>
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
                </>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

              {activeStep + 1 === 1 && (
                <div>
                  <Box
                    component="form"
                    className=""
                    sx={{
                      "& .MuiTextField-root": { m: 1 }
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
               
                        <>
                          <div>
                            <h3 id="h3_Title">Die Kontaktangaben</h3>
                            <form onSubmit={formik.handleSubmit}>
                              
                            <TextField
                             type="email"
                             id="textField"
                             label="E-Mail-Adresse"
                             onChange={(evt) => {setEmail(evt.target.value.replace(/\s/g, ''))}} 
                             helperText={EmailValdition_helperTextMess}
                            />

                           <TextField
                              type="text"
                              required
                              id="textField"
                              label="Vor- und Nachname"
                              onChange={(evt) => {setvorundNachname(evt.target.value)}} 
                              helperText={helperTextMess}
                            />
                            {/* <TextField
                             id="textField"
                              label="Mobiltelefonnummer"
                            /> */}
                            <h3 id="h3_Title">Kennwort erstellen</h3>
                            <TextField
                              id="password_TextField"
                              label="Kennwort"
                              onChange={(evt) => {setPassword(evt.target.value.replace(/\s/g, ''))}} 
                              helperText={Password_helperTextMess}
                              autoComplete="current-password"
                              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                              InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            
                            </form>
                          </div>
                        
                        </>
                    </div>                  
                  </Box>
                

                  <Box sx={{ "& button": { m: 1 } }}>
      <div>
      <Button
        disabled={
          VorundNachname == ""  
          || Email == ""
          || password == ""
          || NotValid == false

        }
       onClick={handleNext}
       variant="contained"
       size="medium"
      style={{
        // background:'rgb(0, 127, 255)',
        borderRadius:'20px',
        textTransform: "none",
        minWidth: "320px",
        minHeight: "42px",
       
      }}
        >
          Weiter
        </Button>
      </div>
    </Box>

                  <br /> <br />
                 <div >
                  <Button variant="outlined" style={{textTransform:'none', color:'#007FFF'}}
                  onClick={() => navigate('business') }
                  
                  >
                  {/* Geschäftskonto erstellen */}
                  Ein kostenloses Unternehmenskonto erstellen
                  </Button>
                  </div>
    </div>
              )}
    

    {isEmailinUse == true && (
          
          <div>
          <br />
          <h3 style={{color:'red'}} >E-Mail wird bereits verwendet</h3>
          <h5 style={{lineHeight:'1.9'}}>
          Diese E-Mail wird bereits verwendet. Bitte verwenden Sie eine
              andere E-Mail!
          </h5>
          <br />
        </div>
        
        )}


              {isEmailinUse == false && activeStep + 1 === 2 && (
                <div>
                  <br />
                  <h3>EMail-Adresse Verifizierung</h3>
                  <h5 style={{lineHeight:'1.9'}}>
                    Zur Überprüfung deine Email haben wir einen Bestätigungslink an &nbsp;
                    {Email} gesendet. Bitte überprüfe deine Email Posteingang oder Spam Eingang
                       und bestätige deine E Mail Adresse.
                  </h5>
                  <br />
                </div>
              )}
            </React.Fragment>
            
          )
          
          }

{isEmailinUse == false && activeStep != 0 && (
                <Button  
                
                
                //  disabled={
                //   GeschäftsKonto_VorundNachname == ""
                //   || GeschäftsKonto_Email == ""
                //   || GeschäftsKonto_Mobiltelefonnummer == ""
                //   || Geschäftsname == ""
                //   || Märkte == ""
                //   || ShippingTo ==  ""
                //   || Kontoinhaber == ""
                //   || IBAN == ""
                //   || SwiftCode == ""
                //   || GeschäftsKonto_password == ""
                //   || GeschäftsKonto_RepPassword == ""
                // }
 
                style={{textTransform:'none', margin:'auto',fontWeight:'bold'}} onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "fertigstellen" : "Weiter"}
                </Button>    
                )}


{isEmailinUse == true && activeStep != 0 && (
                <Button  
                style={{textTransform:'none', margin:'auto',fontWeight:'bold'}} onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "zurück" : "Weiter"}
                </Button>    
                )}


           </Paper>

         

        </Box>
      {/* <Footer /> */}
      <Footer />
       </>
      );
    }
export default Signup

