import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Home from './pages/home/Home.js'
import SellerDashboard from './pages/SellerDashboard/Dashboard.js'
import SearchPage from './pages/search/Search.js'
import Signup from './Auth/Signup/Signup.js'
import BusinessAccountSignup from './Auth/Signup/BusinessAccountSignup.js'
import UserAccountSignup from './Auth/Signup/UserAccountSignup'
import Login from './Auth/login/Login.js'
import UploadContainer from './pages/Upload.Products/Products/UploadContainer'
import ProductManagement from './pages/Product.Management/Product.Management'
import ProductPage from './pages/productPage/ProductPage'
import OrderPage from './pages/SellerDashboard/OrderPage'
import NewOrderForm from  './pages/newOrder/NewOrderForm'
import AccountPage from  './pages/Buyer/AccountPage'
import OrdermadeSuccessfully from './pages/newOrder/OrdermadeSuccessfully'

import Orders from './pages/Buyer/Orders'
import './Styles/fonts.css'
import AboutUs from './pages/About_Nearwide/AboutUs.js';
import Elektronik from "./pages/Categories/Elektronik.js"
import UnderMaintenance from './pages/UnderMaintenance/UnderMaintenance'
import LocalShopping from './pages/LocalShopping/LocalShopping'
import Local_Delivery from './pages/About_Nearwide/Local_Delivery.js'

import AGB from './pages/Legal_Pages/AGB'
import Impressum from './pages/Legal_Pages/Impressum'
import PrivacyPolicy from './pages/Legal_Pages/PrivacyPolicy'
import StorePage from './pages/Shops/StorePage.js';


export default function App() {
  sessionStorage.setItem("SearchValue", "");
  sessionStorage.setItem("Location", "Deutschlandweit");
  return (
    <Routes>
       <Route exact path="/" element={<Home />} />
       <Route exact path="/suche" element={<SearchPage />} />
       <Route exact path="/anmelden" element={<Login />} />
       {/* <Route exact path="/registrieren" element={<Signup />} /> */}
       <Route exact path="/registrieren" element={<UserAccountSignup />} />
       <Route exact path="/registrieren/business" element={<BusinessAccountSignup />} />
       <Route exact path="/Dashboard" element={<SellerDashboard />} />
       <Route exact path="/Bestellung.id/:uid" element={<OrderPage />} />
       <Route exact path="/suche/bestellen/Productid/:uid" element={<NewOrderForm />} />
       <Route exact path="/Produkte/hochladen" element={<UploadContainer  />} />
       <Route exact path="/Produkte/Verwaltung" element={<ProductManagement />} />
       {/* <Route exact path="suche/Productid=:uid" element={<ProductPage />} /> */}

      <Route exact path="/meine-Bestelungen" element={<Orders />} />
      <Route exact path="/Account/:123" element={<AccountPage />} />
      <Route exact path="/Successful" element={<OrdermadeSuccessfully />} />
      <Route exact path="/über-uns" element={<AboutUs />} /> {/* New About Us route */}
      <Route exact path="/Kategorien/Elektronik" element={<Elektronik />} /> 
      <Route exact path="/UnderMaintenance" element={<UnderMaintenance />} /> 
      <Route exact path="/lokal/einkaufen" element={<LocalShopping />} /> {/* New About Us route */}
      
      <Route exact path="suche/Productid/:uid" element={<ProductPage />} />
      <Route exact path="/Kategorien/Elektronik/suche/Productid/:uid" element={<ProductPage />} />
      <Route exact path="/lokal/einkaufen/suche/Productid/:uid" element={<ProductPage />} />
      <Route exact path="/stores/page/:uid/suche/Productid/:uid" element={<ProductPage />} />

      <Route exact path="/lokale.Zustellung" element={<Local_Delivery />} />
      
      
      <Route exact path="/AGB" element={<AGB />} />
      <Route exact path="/Impressum" element={<Impressum />} />
      <Route exact path="/Datenschutzrichtlinie" element={<PrivacyPolicy />} />

      <Route exact path="/stores/page/:uid" element={<StorePage />} />
     


    </Routes>
  );
}
 
 