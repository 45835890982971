import React, { useState, useEffect } from "react";
import { firestore } from "../../firebaseConfig";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Button from "@mui/material/Button";
import "./ProductPage.css";
import firebase from "firebase/compat/app"; //v9
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Stack from "@mui/material/Stack";
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ProductPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const [userState, setUserState] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [countryWideShipping, setCountryWideShipping] = useState(false);
  const [localShippingTime, setLocalShippingTime] = useState("");

  const currentURL = window.location.pathname;
  const productIdPattern = /\/Productid\/([^\/]+)/;
  const match = productIdPattern.exec(currentURL);
  const productId = match ? match[1] : null;



  useEffect(() => {
    if (productId) {
      const subscriber = firestore
        .collection("products")
        .doc(productId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data();
            setCountryWideShipping(data.GermanyWideShipping);
            setLocalShippingTime(data.localShippingTime);
            setProduct(data);
            setLoading(false);
          } else {
            console.error("No such document!");
            setLoading(false);
          }
        });

      return () => subscriber();
    } else {
      console.error("Invalid product ID");
      setLoading(false);
    }
  }, [productId]);

  if (loading) {
    return (
      <div className="Loader">
        <div className="lds-hourglass"></div>
      </div>
    );
  }


  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      // User is signed in.
      // console.log("User is signed in. =>", user.email);
      setUserState(true);
    } else {
      // No user is signed in.
      console.log("No user is signed in.");
    }
  });

  const handleSubmit = () => {
    if (userState) {
      navigate(`/suche/bestellen/Productid/${productId}`);
    } else {
      navigate("/anmelden");
    }
  };

  return (
    <>
      <Header />
      <div className="content">
        <div className="product-page">
          {/* <Stack direction="row" spacing={2} className="backButton">
            <Button
              style={{ textTransform: "none" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosIcon /> Zurück
            </Button>
          </Stack> */}
          <h3>{product.title}</h3>
          <div className="product-content">
            <div className="product-slider">
              <Slider {...settings}>
                {product.Product_imgs && product.Product_imgs.map((img, index) => (
                  <div key={index}>
                    <img src={img} alt={`Product ${index + 1}`} />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="product-details">
              <h3>{product.ProductName}</h3>
              <a style={{textDecoration:'underline', cursor:'pointer', color:'#007bff'}} onClick={() => navigate(`/stores/page/${product.sellerId}`)}>Besuche den {product.BrandName} Store</a>
             
             {/* <Button onClick={() => { navigate(`Productid/${Product.ProductID}`); }}></Button> */}
              <Button
                onClick={handleSubmit}
                size="large"
                variant="contained"
                sx={{
                  textTransform: 'none',
                  borderRadius: '15px',
                  maxWidth: '22rem',
                  background: '#007FFF',
                  '&:hover': {
                    background: '#005BB5',
                  },
                  marginTop: '1rem',
                }}
              >
                Jetzt bestellen
              </Button>
              <h2><span style={{ fontSize: '0.8em', color:'red' }}>{product.discount}</span> {product.Product_Price}€</h2>  
              <hr />
          <div className="product-checklist" >
            <List>
              <ListItem sx={{ display: "flex", justifyContent: "space-between", padding: '10px 0' }}>
                <ListItemText primary="Kostenlose Lieferung" />
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <CheckCircleIcon sx={{ color: 'green' }} />
                </ListItemIcon>
              </ListItem>
              <ListItem sx={{ display: "flex", justifyContent: "space-between", padding: '10px 0' }}>
                <ListItemText primary="Kostenlose Rückgabe" />
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <CheckCircleIcon sx={{ color: 'green' }} />
                </ListItemIcon>
              </ListItem>
              <ListItem sx={{ display: "flex", justifyContent: "space-between", padding: '10px 0' }}>
                <ListItemText primary="Jetzt bestellen und in 14 Tagen bezahlen" />
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <CheckCircleIcon sx={{ color: 'green' }} />
                </ListItemIcon>
              </ListItem>
            </List>
          </div>
          <p  >{product.Product_desc}</p>
            </div>
          </div>
         
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductPage;
