import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Button,
  Grid,
  Container,
  Modal,
  Box,
} from "@mui/material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer.js";
import firebase from "firebase/compat/app";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
 
import "firebase/firestore";

// Initialize Firebase with your config
const firebaseConfig = {
  // Your Firebase config
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();

export default function Orders() {
  const [userOrders, setUserOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();


  



  const handleMailModal = (orderData) => {
    try {
      // E-Mail-Vorlage erstellen
      const recipient = "kontakt@nearwide.de";
      const subject = `Bestellung mit der Nummer #${orderData.orderNumber} stornieren`;
      const body = `Hallo Nearwide Team,
  
 ich schreibe Ihnen, um die Stornierung der Bestellung #${
        orderData.orderNumber
      } zu beantragen. 
      
 Hier sind die Details der Bestellung: 

 Produkt: ${orderData.Produktname}

 Bestellungsdatum: ${orderData.createdAt.toDate().toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })}
  
  Produktlink: ${orderData.ProductURL}
  
  
  Mit freundlichen Grüßen,
  ${orderData.firstandLastname}
  `;

      // E-Mail-Client öffnen
      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    } catch (error) {
      console.error("Error creating email template:", error);
    }
  };
  
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in
        setCurrentUser(user.uid);
        // console.log("User UID:", user.uid);
  
        // Fetch orders for the current user
        try {
          // Use the actual Firestore query to fetch user orders
          const querySnapshot = await firestore.collection("orders")
           .where("uid", "==", user.uid)
           .get();
          const data = querySnapshot.docs.map((doc) => doc.data());
          setUserOrders(data);
        } catch (error) {
          console.error("Error fetching user orders:", error);
        }
      } else {
        // User is signed out
        setCurrentUser(null);
        setUserOrders([]); // Clear user orders when signed out
        console.log("User is signed out");
      }
    });
  
    return unsubscribe;
  }, []);
  

  const handleOpenModal = (orderId) => {
    setOrderIdToCancel(orderId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setOrderIdToCancel(null);
    setIsModalOpen(false);
  };

  const handleCancelClick = () => {
    // Implement the logic to cancel or delete the order
    // console.log(`Order ${orderIdToCancel} canceled`);
    // Close the modal after handling the cancellation
    handleCloseModal();
  };

  // Define the function to handle the back action
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Container sx={{ paddingTop: 8 }}>
        <Header />

        <Button
          style={{ textTransform: "none", bottom: "50px" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon /> Zurück
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>

          <p style={{ marginLeft: "30px" }}>Meine Bestellungen</p>
          <Grid item xs={12}>
            <List>
              {userOrders.map((order, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    {/* Assuming order.imageUrl is one of your fields */}
                    <img
                      src={order.productImage}
                      alt={`Bestellung €{order.orderNumber}`}
                      style={{
                        marginRight: "16px",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                    <ListItemText
                      primary={`Bestellungsnummer: ${order.orderNumber}`}
                      secondary={
                        <>
                          <Typography variant="body2" color="textSecondary">
                            {order.createdAt.toDate().toLocaleDateString()}{" "}
                            {/* Assuming createdAt is a timestamp */}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Gesamtbetrag: €{order.productPrice}
                          </Typography>
                        </>
                      }
                    />
           <Button
          variant="outlined"
          color="error"
          onClick={() => handleMailModal(order)}
          style={{ marginLeft: "auto" }}
          sx={{ textTransform: "none" }}
        >
          Stornieren
        </Button>
                  </ListItem>
                  {index < userOrders.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Grid>

        {/* Modal for cancellation confirmation */}
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="cancellation-confirmation-modal"
          aria-describedby="cancellation-confirmation-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "white",
              boxShadow: 24,
              p: 2,
            }}
          >
            <Typography
              id="cancellation-confirmation-modal"
              variant="h6"
              component="div"
              gutterBottom
            >
              Bestellung stornieren
            </Typography>
            <Typography
              id="cancellation-confirmation-description"
              sx={{ mt: 2 }}
            >
              Bist du sicher, dass du die Bestellung stornieren möchtest?
            </Typography>
            <Button
              onClick={handleCancelClick}
              variant="contained"
              color="error"
              sx={{ mt: 2 }}
              style={{ textTransform: "none" }}
            >
              Stornieren
            </Button>
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              color="primary"
              sx={{ mt: 2, ml: 1 }}
              style={{ textTransform: "none" }}
            >
              Abbrechen
            </Button>
          </Box>
        </Modal>
      </Container>
      <Footer />
    </>
  );
}
