import React from 'react';
import { Link } from 'react-router-dom';
import './Datenschutz.css';
import Footer from '../../components/Footer/Footer.js';
import Header from '../../components/Header/Header.js';

const Datenschutz = () => {
  return (
    <div className="wrapper">
    <Header />
      <section>
        <div className="datenschutz-container">
          <h2>Datenschutzrichtlinie</h2>
          <div className="datenschutz-content">
            <p>
              Diese Datenschutzrichtlinie erklärt, wie wir Informationen sammeln, verwenden, offenlegen und verarbeiten, einschließlich personenbezogener Daten, im Zusammenhang mit der Nutzung unserer Dienste.
            </p>
            <h3>Welche Informationen wir sammeln:</h3>
            <ul>
              <li>Personenbezogene Daten: Wir können personenbezogene Daten wie Name, Adresse, E-Mail-Adresse und Telefonnummer sammeln, wenn Sie unsere Dienste nutzen oder mit uns interagieren.</li>
              <li>Nicht-personenbezogene Daten: Wir sammeln möglicherweise auch nicht-personenbezogene Daten, die nicht direkt mit einer identifizierten oder identifizierbaren Person in Verbindung gebracht werden können.</li>
            </ul>
            <h3>Wie wir Informationen sammeln:</h3>
            <ul>
              <li>Direkte Eingabe: Sie geben uns möglicherweise direkt Informationen, indem Sie Formulare ausfüllen oder auf andere Weise mit unseren Diensten interagieren.</li>
              <li>Automatisch: Wir sammeln möglicherweise automatisch bestimmte Informationen über Ihre Geräte und Ihre Nutzung unserer Dienste durch Cookies und ähnliche Technologien.</li>
            </ul>
            <h3>Wie wir Informationen verwenden:</h3>
            <ul>
              <li>Bereitstellung und Verbesserung der Dienste: Wir verwenden die gesammelten Informationen, um Ihnen unsere Dienste bereitzustellen und sie zu verbessern.</li>
              <li>Kommunikation: Wir können Ihre Kontaktdaten verwenden, um mit Ihnen zu kommunizieren, etwa um auf Ihre Anfragen zu antworten oder Ihnen wichtige Benachrichtigungen zu senden.</li>
            </ul>
            <h3>Wie wir Informationen weitergeben:</h3>
            <ul>
              <li>Geschäftliche Partner: Wir können bestimmte Informationen an unsere Geschäftspartner weitergeben, die uns bei der Bereitstellung und Verbesserung unserer Dienste unterstützen.</li>
              <li>Rechtliche Anforderungen: Wir können Informationen offenlegen, wenn wir glauben, dass dies gesetzlich erforderlich ist oder um unsere Rechte, Eigentum oder Sicherheit zu schützen.</li>
            </ul>
            <h3>Datenschutz und Sicherheit:</h3>
            <p>Wir ergreifen angemessene Sicherheitsmaßnahmen, um Ihre Informationen vor unbefugtem Zugriff, Offenlegung, Änderung oder Zerstörung zu schützen.</p>
            <h3>Änderungen an dieser Richtlinie:</h3>
            <p>Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Bitte überprüfen Sie diese Seite regelmäßig auf Änderungen.</p>
            <h3>Kontakt:</h3>
            <p>Bei Fragen zu dieser Datenschutzrichtlinie kontaktieren Sie uns bitte unter <a href="mailto:kontakt@nearwide.de">kontakt@nearwide.de</a>.</p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Datenschutz;
