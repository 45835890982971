import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

export default function DrawerAppBar(props) {
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        style={{  background: "#fff", color: "rgb(0, 127, 255)" }}
      >
        <Toolbar>
          <Typography style={{ fontWeight: "bold" }} variant="h6" component="div">
            Nearwide
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
