// AboutUs.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './About_Nearwide.css'; // Stildefinitionen
import Footer from '../../components/Footer/Footer.js';
import Header from '../../components/Header/Header.js';

const AboutUs = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="wrapper">
      <Header/>
      <section className="content">
        <h2 className='header'>Über Nearwide</h2>
        <br />
        <p>
         Mit Nearwide hast du die Möglichkeit, Produkte und Dienstleistungen in deiner unmittelbaren Umgebung sowie landesweit zu erwerben. Unser Ziel ist es, Märkte für dich zugänglicher zu machen, sodass du Bestellungen besonders schnell erhältst. Dabei legen wir Wert auf Vielfalt und Wahlfreiheit, sodass du sicher und einfach Produkte von überall aus bestellen kannst.
        </p>

        <h4>Mission:</h4>
        <p>Unsere Mission ist es, Kunden dabei zu unterstützen, eine Vielzahl von lokalen und nationalen Einkaufsmöglichkeiten durch Nearwide zu entdecken. Wir streben danach, einzigartige Einkaufserlebnisse zu ermöglichen, indem wir nicht nur eine breite Auswahl an Produkten bieten, sondern auch durch schnellere Lieferzeiten, die durch regionales Einkaufen und landesweiten Versand optimiert werden.</p>
        
        <h4>Vision:</h4>
        <p>Wir setzen uns dafür ein, die lokale Wirtschaft zu stärken, indem wir Unternehmen die Möglichkeit geben, ihre Produkte über Nearwide sowohl lokal als auch landesweit zu vertreiben. Unsere Vision ist es, eine dynamische Plattform zu schaffen, die es Kunden ermöglicht, mit Leichtigkeit und Vertrauen in die Vielfalt und Qualität lokaler und nationaler Produkte zu investieren, während gleichzeitig die Beziehung zwischen Verbrauchern und lokalen Unternehmen gestärkt wird.</p>
        
        {/* <h4>Was macht Nearwide einzigartig ?</h4>
        <p>Mit Nearwide lokale Zustellung erhältst Du Deine Bestellung spätestens am nächsten Tag.</p> */}

        <br />
        <Link to="/">Zurück</Link>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
