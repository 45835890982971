import React from "react";
import { useNavigate } from "react-router-dom";
import "./UnderMaintenance.css"; // Import CSS for styles
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Footer from "../../components/Footer/Footer.js";

function ProductManagement() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    // Use navigate(-1) directly in the callback function
    navigate(-1);
  };

  return (
    <>
      {/* <PageHeader /> */}
      <br />
      <br />
      <br />
      <div className="product-management-container">
        <h1 className="page-title">Wartung in Bearbeitung</h1>
        <p className="maintenance-message">
          Wir führen gerade Wartungsarbeiten durch. Die Seite steht bald wieder
          zur Verfügung. Vielen Dank für Ihr Verständnis.
        </p>
        <button className="back-button" onClick={handleGoBack}>
          <ArrowBackIcon />
        </button>
        <div className="image-container">
          <img
            className="centered-image"
            src="/undraw_maintenance_re_59vn.svg"
            alt="Wartungsgrafik"
          />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ProductManagement;
