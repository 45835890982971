import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Searchbox from "../../components/Home_Searchbox/Searchbox";
import "./StorePage.css";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebaseConfig";
import MainSlider from "../home/MainSlider.js";

function StorePage() {
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [seller, setSeller] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brandName, setBrandName] = useState('');
  const [sellersUid, setsellersUid] = useState([]);


console.log(brandName)

const url = "/stores/page/AhyuJB6N47gf80W2NqVuQjdFOFJ2";
const urlParts = url.split("/");
const SellerUid = urlParts[urlParts.length - 1];
  
// To fech Brand Name from Sellers collection
  useEffect(() => {
    const getSellersFromFirebase = [];
    const subscriber = firestore
      .collection("Sellers")
      .where("uid", "==", SellerUid) // Filter products by sellerId
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            getSellersFromFirebase.push({
            ...doc.data(), //spread operator
            key: doc.id, // `id` given to us by Firebase
          });
        });
        setSeller(getSellersFromFirebase);
        setLoading(false);
        const BrandName = getSellersFromFirebase.map(seller => seller.BrandName);
        setBrandName(BrandName)
      });
    // return cleanup function
    return () => subscriber();
  }, [loading]); // empty dependencies array => useEffect only called once

// To Fech the Products based on Seller uid
  useEffect(() => {
    const getProductsFromFirebase = [];
    const subscriber = firestore
      .collection("products")
      .where("sellerId", "==", SellerUid) // Filter products by sellerId
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getProductsFromFirebase.push({
            ...doc.data(), //spread operator
            key: doc.id, // `id` given to us by Firebase
          });
        });
        setProduct(getProductsFromFirebase);
        setLoading(false);
      });

    // return cleanup function
    return () => subscriber();
  }, [loading]); // empty dependencies array => useEffect only called once
 
  






  
  if (loading) {
    return (
      <div className="Loader">
        <div className="lds-hourglass"></div>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="Searchbox">
        <Searchbox />
      </div>

      <section className="brand-header">
        <h2>{brandName}</h2>
      </section>


      <div className="grid-container">
        {product.length > 0 ? (
          <>
            {product.map((Product) => (
                <div
                  key={Product.key}
                  className="grid-item"
                  onClick={() => {
                    navigate(`suche/Productid/${Product.ProductID}`);
                  }}
                >
                  <div className="product-img">
                    <img src={Product.Card_imgURL} alt="Product" />
                  </div>
                  <div className="Product-details">
                    <div className="Product-Name">{Product.ProductName}</div>
                    <div className="Product-Price">{Product.Product_Price}€</div>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <h1>Keine Ergebnisse gefunden!</h1>
        )}
      </div>

      <div style={{ textAlign: "center" }} className="HomeContainer">
      </div>

      <Footer />
    </>
  );
}

export default StorePage;
