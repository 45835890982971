import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AppBar from './AppBar'
import { useNavigate } from "react-router-dom";

export default function OrdermadeSuccessfully() {
  const navigate = useNavigate();

  return (
    <>
    <AppBar />

   {/* Show message to the user  */}
  <div style={{position:'relative',top:'100px', textAlign:'center'}}>
   <h2>Die Rechnung erhalten Sie per E-Mail. </h2>
   <h3>Vielen Dank für Ihre Bestellung bei Nearwide DE </h3>
   </div>
  
   <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20vh',
      }}
    >
      <Button
        variant="outlined"
        style={{
          margin: '100px auto 0',
        }}
        onClick={() => {
          navigate("/");
          
        }}
      >
        Zurück
      </Button>
    </div>
    </>
  );
}
 
 