import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import SignupHeader from "./SignupHeader";
import Paper from "@mui/material/Paper";
import "./Signup.css";
import Footer from '../../components/Footer/Footer'
import NativeSelect from "@mui/material/NativeSelect";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { sendSignInLinkToEmail } from "firebase/auth";
import { linkWithCredential, EmailAuthProvider } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import { firestore } from "../../firebaseConfig";
import { sendEmailVerification } from "firebase/auth";
import firebase from "firebase/compat/app"; //v9
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";
import Alert from "@mui/material/Alert";

import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = ["Kleidung", "Gastronomie", "Mode", "Elektronik"];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const steps = ["Kontoerstellung", "Verifizierung"];

const useHelperTextStyles = makeStyles(() => ({
  root: {
    color: "red",
  },
}));

function Signup(props, str) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [accounttype, setaccounttype] = React.useState("");
  const [selected, hasError] = React.useState("");
  const [VorundNachname, setvorundNachname] = React.useState("");
  const [
    GeschäftsKonto_VorundNachname,
    setGeschäftsKonto_VorundNachname,
  ] = React.useState("");
  const [GeschäftsKonto_Email, setGeschäftsKonto_Email] = React.useState("");
  const [
    GeschäftsKonto_Mobiltelefonnummer,
    setGeschäftsKonto_Mobiltelefonnummer,
  ] = React.useState("");
  const [kontoPassword, setkontoPassword] = React.useState("");
  const [
    GeschäftsKonto_RepPassword,
    setGeschäftsKonto_RepPassword,
  ] = React.useState("");
  const [BrandName, setBrandName] = React.useState("");
  // const [Märkte, setMärkte] = React.useState([]);
  const [Kontoinhaber, setkontoinhaber] = React.useState("");
  const [IBAN, setIBAN] = React.useState("");
  const [localShipping, setlocalShipping] = React.useState([]);
  const [wideShipping, setwideShipping] = React.useState(false);

  // const [SwiftCode, setSwiftCode] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [RepPassword, setRepPassword] = React.useState("");
  const [isEmailinUse, setisEmailinUse] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const classes = useStyles();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (isEmailinUse) {
      setActiveStep(0);
    }
  }, [isEmailinUse]);

  if (GeschäftsKonto_RepPassword == "") {
    var helpertextRepPassword = (
      <p style={{ color: "red" }}>Kennwort nochmals eingeben*</p>
    );
  }

  if (GeschäftsKonto_RepPassword != "") {
    var helpertextRepPassword = (
      <p style={{ display: "none" }}>Kennwort nochmals eingeben*</p>
    );
  }

  if (kontoPassword == "") {
    var helpertextpassword = <p style={{ color: "red" }}>Kennwort eingeben*</p>;
  }

  if (kontoPassword != "") {
    var helpertextpassword = (
      <p style={{ display: "none" }}>Kennwort eingeben*</p>
    );
  }

  // if (SwiftCode == "") {
  //   var helpertextSwiftCode = (
  //     <p style={{ color: "red" }}>Swift-code eingeben*</p>
  //   );
  // }

  // if (SwiftCode != "") {
  //   var helpertextSwiftCode = (
  //     <p style={{ display: "none" }}>Swift-code eingeben*</p>
  //   );
  // }

  if (IBAN == "") {
    var helpertextIBAN = <p style={{ color: "red" }}>IBAN eingeben*</p>;
  }

  if (IBAN != "") {
    var helpertextIBAN = <p style={{ display: "none" }}>IBAN eingeben*</p>;
  }

  if (Kontoinhaber == "") {
    var helpertextKontoinhaber = (
      <p style={{ color: "red" }}>Bankkontoinhaber eingeben*</p>
    );
  }

  if (Kontoinhaber != "") {
    var helpertextKontoinhaber = (
      <p style={{ display: "none" }}>Bankkontoinhaber eingeben*</p>
    );
  }

  if (localShipping == "") {
    var helpertexlocalShipping = (
      <p style={{ color: "red" }}>Liefergebiete eingeben*</p>
    );
  }

  if (localShipping != "") {
    var helpertexlocalShipping = (
      <p style={{ display: "none" }}>Liefergebiete eingeben*</p>
    );
  }

  if (BrandName == "") {
    var helpertextBrandName = (
      <p style={{ color: "red" }}>BrandName eingeben*</p>
    );
  }

  if (BrandName != "") {
    var helpertextBrandName = (
      <p style={{ display: "none" }}>BrandName eingeben*</p>
    );
  }

  if (GeschäftsKonto_Mobiltelefonnummer == "") {
    var helperhelperGftsKonto_Motelefonnummer = (
      <p style={{ color: "red" }}>Handynummer eingeben*</p>
    );
  }

  if (GeschäftsKonto_Mobiltelefonnummer != "") {
    var helperhelperGftsKonto_Motelefonnummer = (
      <p style={{ display: "none" }}>Handynummer eingeben*</p>
    );
  }

  if (VorundNachname == "" || GeschäftsKonto_VorundNachname == "") {
    var helperTextMess = (
      <p style={{ color: "red" }}>Vor- und Nachname eingeben*</p>
    );
  }

  if (VorundNachname != "" || GeschäftsKonto_VorundNachname !== "") {
    var helperTextMess = (
      <p style={{ display: "none" }}>vor- und Nachname eingeben</p>
    );
  }

  if (Email == "" || GeschäftsKonto_Email == "") {
    var Email_helperTextMess = (
      <p style={{ color: "red" }}>E-Mail Adresse eingeben*</p>
    );
  }

  if (Email != "" || GeschäftsKonto_Email !== "") {
    var Email_helperTextMess = (
      <p style={{ display: "none" }}>E-Mail Adresse eingeben</p>
    );
  }

  if (password == "") {
    var Password_helperTextMess = (
      <p style={{ color: "red" }}>Kennwort eingeben*</p>
    );
  }

  if (password != "") {
    var Password_helperTextMess = (
      <p style={{ display: "none" }}>Kennwort eingeben</p>
    );
  }

  if (RepPassword == "") {
    var RepPass_helperTextMess = (
      <p style={{ color: "red" }}>Kennwort nochmals eingeben*</p>
    );
  }

  if (RepPassword != "") {
    var RepPass_helperTextMess = (
      <p style={{ display: "none" }}>Kennwort nochmals eingeben</p>
    );
  }


  // After complete the steps go to login page
  if (activeStep == 3) {
    navigate("/anmelden");
  }

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "foobar",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handlelocalShipping = (e, value) => {
    if (value) {
      console.log(value.map((option) => option.Plz));
    }

    setlocalShipping(value);
  };

  function CreateNewSellerAccount() {
    createUserWithEmailAndPassword(auth, GeschäftsKonto_Email, "password")
      .then((userCredential) => {
        // var user = userCredential.user;
        var user = firebase.auth().currentUser;
        var uid = user.uid;

        // Add a new document in collection "Sellers"
           firestore
          .collection("Sellers")
          .doc(uid)
          .set({
            VorundNachname: GeschäftsKonto_VorundNachname,
            Email: user.email,
            BrandName: BrandName,
            Mobiltelefonnummer: GeschäftsKonto_Mobiltelefonnummer,
            localShipping: localShipping,
            GermanyWideShipping: wideShipping,
            BankKontoinhaber: Kontoinhaber,
            IBAN: IBAN,
            // SwiftCode: SwiftCode,
            Accounttype: "Seller",
            uid: uid
          })
          .then(() => {
            console.log("Seller account created successfully!");

            // Send email verification
            user
              .sendEmailVerification()
              .then(() => {
                console.log("Email verification sent");
              })
              .catch((error) => {
                console.error("Error sending email verification: ", error);
              });
          })
          .catch((error) => {
            console.error("Error creating seller account: ", error);
          });
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          // console.log("Email is already in use:!!!!");
          setisEmailinUse(true);
        } else {
          console.error("Error creating user account: ", error);
        }
      });
  }

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });

  const handleMärkteChange = (event) => {
    const {
      target: { value },
    } = event;
    // setMärkte(
    // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleAccounttypeChange = (event) => {
    setaccounttype(event.target.value);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    CreateNewSellerAccount();

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSendSelection = (event) => {
    console.log("value - ", event.target.value);
  };

  const [name, setName] = useState();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register("", { required: true });

  const currentName = watch("name");

  function onSubmit(data) {
    setName(data.name);
    reset();
  }

  // ==========================================
  function handleSelect12Change(e) {
    let isChecked = e.target.checked;

    console.log(isChecked);
    // do whatever you want with isChecked value
  }

  function getChckeboxValue(event) {
    const value = event.target.value;
    console.log(value);
  }
  // ==========================================
  const helperTextStyles = useHelperTextStyles();
  return (
    <>
      <SignupHeader />

      <Box className="stepperContainer">
        {isEmailinUse == true && (
          <Stack
            sx={{
              width: "100%",
              position: "fixed",
              top: 55,
              zIndex: 9999, // Adjust the z-index value as needed
            }}
            spacing={2}
          >
            <Alert severity="error">
              Diese E-Mail wird bereits verwendet. Bitte verwenden Sie eine
              andere E-Mail!
            </Alert>
          </Stack>
        )}

        <Paper className="Paper" elevation={1}>
          <Stepper activeStep={activeStep} className="Stepper">
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <>
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                </>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              {activeStep + 1 === 1 && (
                <div>
                  <Box
                    component="form"
                    className=""
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <>
                        <h3 id="h3_Title">Die Kontaktangaben</h3>
                        <TextField
                          required
                          id="textField"
                          label="Vor- und Nachname"
                          onChange={(evt) => {
                            setGeschäftsKonto_VorundNachname(evt.target.value);
                          }}
                          helperText={helperTextMess}
                        />

                        <TextField
                          id="textField"
                          label="E-Mail-Adresse"
                          onChange={(evt) => {
                            setGeschäftsKonto_Email(
                              evt.target.value.replace(/\s/g, "")
                            );
                          }}
                          helperText={Email_helperTextMess}
                        />

                        <TextField
                        className={classes.input}
                          id="textField"
                          type="number"
                          label="Mobiltelefonnummer"
                          onChange={(e) =>
                            setGeschäftsKonto_Mobiltelefonnummer(
                              e.target.value.replace(/\s/g, "")
                            )
                          }
                          helperText={helperhelperGftsKonto_Motelefonnummer}
                        />

                        <h3 id="h3_Title">Die Geschäftsangaben</h3>
                        <TextField
                          required
                          id="textField"
                          label="die BrandName"
                          onChange={(e) => setBrandName(e.target.value)}
                          helperText={helpertextBrandName}
                        />
                        {/* <FormControl sx={{ m: 1, minWidth: 330 }}></FormControl>
                        <p id="delivery_Locations_Title">
                          Zu welchen Orten können Sie Ihre Produkte innerhalb
                          von zwei Stunden oder weniger liefern?
                        </p> */}
                        {/* Here Started */}
                        {/* <Stack spacing={3} sx={{ width: 500 }}>
                          <Autocomplete
                            multiple
                            options={GermanCities}
                            onChange={handlelocalShipping}
                            getOptionLabel={(option) => option.Ort}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option.Ort}
                                  {...getTagProps({ index })}
                                  deleteIcon={<ClearIcon />}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                style={{ maxWidth: "340px" }}
                                {...params}
                                // variant="filled"
                                label="Die Deutschland's Städte"
                                placeholder="Wählen Sie die Städte aus, in die Ihre Produkte schnell versendet werden"
                              />
                            )}
                          />
                        </Stack> */}
                        {/* Here End */}
                        {/* <FormGroup id="delivery_to_all_German_Cities">
                          <hr />

                          <FormControlLabel
                            onClick={() => {
                              setwideShipping(true);
                            }}
                            control={<Checkbox />}
                            label="Wollen Sie auch deutschlandweit verkaufen?"
                          />
                          <div
                            style={{
                              fontSize: "13px",
                              fontFamily: 'Roboto", sans-serif',
                            }}
                          >
                            {helpertexlocalShipping}
                          </div>
                        </FormGroup> */}

                        <br />
                        <h3>Bankkonto</h3>

                        <TextField
                          onChange={(e) => setkontoinhaber(e.target.value)}
                          required
                          id="textField"
                          label="Kontoinhaber"
                          helperText={helpertextKontoinhaber}
                        />

                        <TextField
                          onChange={(e) =>
                            setIBAN(e.target.value.replace(/\s/g, ""))
                          }
                          required
                          id="textField"
                          label="IBAN"
                          helperText={helpertextIBAN}
                        />
                      {/*
                        <TextField
                          required
                          id="textField"
                          label="BIC (Swift-Code)"
                          onChange={(e) =>
                            setSwiftCode(e.target.value.replace(/\s/g, ""))
                          }
                          helperText={helpertextSwiftCode}
                        />
                        */}
                        <h3 id="h3_Title">Kennwort erstellen</h3>
                        <TextField
                          id="password_TextField"
                          label="Kennwort"
                          autoComplete="current-password"
                          onChange={(evt) => {
                            setkontoPassword(
                              evt.target.value.replace(/\s/g, "")
                            );
                          }}
                          helperText={helpertextpassword}
                          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          id="password_TextField"
                          label="Kennwort nochmals eingeben"
                          onChange={(evt) => {
                            setGeschäftsKonto_RepPassword(
                              evt.target.value.replace(/\s/g, "")
                            );
                          }}
                          helperText={helpertextRepPassword}
                          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                    </div>

                    <Box sx={{ "& button": { m: 1 } }}>
                      <div>
                        <Button
                          disabled={
                            GeschäftsKonto_VorundNachname == "" ||
                            GeschäftsKonto_Email == "" ||
                            GeschäftsKonto_Mobiltelefonnummer == "" ||
                            BrandName == "" ||
                            // || Märkte == ""
                            // localShipping == "" ||
                            Kontoinhaber == "" ||
                            IBAN == "" ||
                            // SwiftCode == "" ||
                            kontoPassword == "" ||
                            GeschäftsKonto_RepPassword == ""
                          }
                          onClick={handleNext}
                          variant="contained"
                          size="medium"
                          style={{
                            // background:'rgb(0, 127, 255)',
                            borderRadius: "20px",
                            textTransform: "none",
                            minWidth: "300px",
                            minHeight: "42px",
                          }}
                        >
                          Weiter
                        </Button>
                      </div>
                      <div style={{ paddingTop: "10px" }}>
                        <Typography variant="p">
                          Du hast schon einen Account?
                          <span
                            style={{ color: "#007FFF", cursor: "pointer" }}
                            onClick={() => {
                              navigate("/anmelden");
                            }}
                          >
                            Anmelden
                          </span>
                        </Typography>
                      </div>
                    </Box>
                  </Box>
                </div>
              )}

              {isEmailinUse == false && activeStep === 1 && (
                <div>
                  <br />
                  <h3>EMail-Adresse Verifizierung</h3>
                  <h5 style={{ lineHeight: "1.9" }}>
                    Zur Überprüfung deine Email haben wir einen Bestätigungslink
                    an &nbsp;
                    {GeschäftsKonto_Email} gesendet. Bitte überprüfe deine Email
                    Posteingang oder Spam Eingang und bestätige deine E Mail
                    Adresse.
                  </h5>
                  <br />
                </div>
              )}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                {activeStep != 0 && (
                  <Button
                    style={{ fontWeight: "bold", textTransform: "none" }}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    zurück
                  </Button>
                )}
                <Box sx={{ flex: "1 1 auto" }} />

                {activeStep === steps.length - 1 && (
                  <>{CreateNewSellerAccount()}</>
                )}
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Box>
      <Footer />
    </>
  );
}

const GermanCities = [

  { Ort: "Heilbronn",    Plz: 74072, Bundesland: "Baden-Württemberg" },
  // { city: "Bretzfeld",    Plz: 74626, Bundesland: "Baden-Württemberg" },
  // { city: "Dörzbach",     Plz: 74677, Bundesland: "Baden-Württemberg" },
  { Ort: "Forchtenberg", Plz: 74670, Bundesland: "Baden-Württemberg" },
  { Ort: "Ingelfingen",  Plz: 74653, Bundesland: "Baden-Württemberg" },
  // { city: "Krautheim",    Plz: 74238, Bundesland: "Baden-Württemberg" },
  { Ort: "Künzelsau",    Plz: 74653, Bundesland: "Baden-Württemberg" },
  { Ort: "Kupferzell",   Plz: 74635, Bundesland: "Baden-Württemberg" },
  // { city: "Mulfingen",    Plz: 74673, Bundesland: "Baden-Württemberg" },
  { Ort: "Neuenstein",   Plz: 74632, Bundesland: "Baden-Württemberg" },
  { Ort: "Niedernhall",  Plz: 74676, Bundesland: "Baden-Württemberg" },
  { Ort: "Öhringen",     Plz: 74613, Bundesland: "Baden-Württemberg" },
  // { city: "Pfedelbach",   Plz: 74629, Bundesland: "Baden-Württemberg" },
  // { city: "Schöntal",     Plz: 74214, Bundesland: "Baden-Württemberg" },
  { Ort: "Waldenburg",   Plz: 74638, Bundesland: "Baden-Württemberg" },
  // { city: "Weißbach",     Plz: 74679, Bundesland: "Baden-Württemberg" },
  // { city: "Zweiflingen",  Plz: 74639, Bundesland: "Baden-Württemberg" },
];

export default Signup;
