// AGB.js

import React from 'react';
import { Link } from 'react-router-dom';
import './AGB.css'; 
import Footer from '../../components/Footer/Footer.js';
import Header from '../../components/Header/Header.js';
const AGB = () => {
  return (
    <div className="wrapper">
      <Header/>
      <section>
        <div className="agb-container">
          <h2>Allgemeine Geschäftsbedingungen (AGB)</h2>
          <div className="agb-content">
            <p>
              Diese Allgemeinen Geschäftsbedingungen ("AGB") regeln die Nutzung der Dienste und den Kauf von Produkten auf der Plattform Nearwide. Durch die Nutzung unserer Dienste oder den Kauf von Produkten erklären Sie sich mit diesen AGB einverstanden.
            </p>

            <h3>1. Nutzung der Dienste</h3>
            <p>
              1.1. Sie dürfen die Dienste von Nearwide nur gemäß diesen AGB nutzen.<br />
              1.2. Sie sind verantwortlich für alle Aktivitäten, die unter Ihrem Konto stattfinden, und für die Geheimhaltung Ihres Passworts.<br />
              1.3. Nearwide behält sich das Recht vor, jederzeit und ohne Vorankündigung Dienste zu ändern, auszusetzen oder einzustellen.
            </p>

            <h3>2. Kauf von Produkten</h3>
            <p>
              2.1. Durch den Kauf von Produkten auf Nearwide erklären Sie sich damit einverstanden, die angegebenen Preise zu zahlen und die Bedingungen der jeweiligen Verkäufer zu akzeptieren.<br />
              2.2. Nearwide ist nicht verantwortlich für die Qualität, Verfügbarkeit oder Lieferung der Produkte, da dies in der Verantwortung der Verkäufer liegt.<br />
              2.3. Rückgabe und Rückerstattung von Produkten unterliegen den Bedingungen der jeweiligen Verkäufer.
            </p>

            <h3>3. Haftungsausschluss</h3>
            <p>
              3.1. Nearwide übernimmt keine Haftung für Schäden, die aus der Nutzung der Dienste oder dem Kauf von Produkten entstehen.<br />
              3.2. Die Nutzung der Dienste erfolgt auf eigenes Risiko.
            </p>

            <h3>4. Änderungen an den AGB</h3>
            <p>
              4.1. Nearwide behält sich das Recht vor, diese AGB jederzeit zu ändern. Änderungen werden auf der Website veröffentlicht und treten mit ihrer Veröffentlichung in Kraft.
            </p>

            <h3>5. Kontakt</h3>
            <p>
              Bei Fragen zu diesen AGB kontaktieren Sie uns bitte unter <a href="mailto:kontakt@nearwide.de">kontakt@nearwide.de</a>.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AGB;
