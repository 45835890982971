import React, { useEffect, useState } from "react";
import PageHeader from "../UploadProductsHeader";
import {
  BrowserRouter as Router,
  useParams,
  Link,
  useLocation,
} from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import Margherita from "./Products/Product_Category/Food/Pizza/Margherita";
// import BrowseCategories from "./Products/Product_Category/BrowseCategories";

import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CategoryMainTable from "../Products/CategoryMainTable";
import UploadNew_Product from "../uploadNew_Product";
import "../Products/CategoryMainTable.css";
import Upload from "../upload";

var path = "root/";
const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

const style = {
  width: "100%",
  maxWidth: 860,
  bgcolor: "#f3f3f3",
  position: "relative",
  top: "6px",
};

export default function UploadProducts() {
  return (
    <>
      <div className="Header" style={{ paddingTop: "50px" }}>
        <PageHeader />
        <br />
      </div>

      <div className="TableContainer">
        <h3>Neues Produkt hochladen</h3>
        <h4 style={{ color: "rgba(0, 0, 0, 0.54)" }}>
          ProduktKategorie auswählen &rarr; Produkt hochladen
        </h4>

        {/* <div style={{display:'grid', placeItems:'center'}}> */}
        <UploadNew_Product />
        {/* </div> */}
      </div>
    </>
  );
}
