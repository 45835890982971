import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


const styles = (theme) => ({
  paper: {
    maxWidth: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
 
  }
});

function createData(name, calories, fat, carbs, protein) {
  return { name, protein };
}
if(1==1) {
  var rows = [
    createData("Frozen yoghurt"),
    createData("Ice cream sandwich"),
    createData("Eclair"),
    createData("Eclair"),
    createData("Eclair"),
  
    // createData("Eclair", 262, 16.0, 24, 6.0),
    // createData("Eclair", 262, 16.0, 24, 6.0),
    // createData("Eclair", 262, 16.0, 24, 6.0),
    // createData("Eclair", 262, 16.0, 24, 6.0)
  ];
}


if(1==2) {
  var rows = [
    createData("Frozen yoghurt"),
    createData("Ice cream sandwich"),
    createData("Eclair", 2),
    createData("Eclair"),
    createData("Eclair"),
    createData("Eclair"),
    createData("Eclair"),
    createData("Eclair"),
    createData("Eclair")
  ];
}

export default function AcccessibleTable(props) {

  return (
    <>
   
   <Box
      sx={{
        width: 500,
        minWidth: '100%',
      }}
    >
      <TextField fullWidth label="fullWidth" id="fullWidth" />
    </Box>

    <TableContainer
      component={Paper}
      style={{overflowX: "hidden", height: "400px" }}
    
    >

      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        
      
        <TableBody >
          {rows.map((row) => (
            <TableRow key={row.name} style={{cursor:"pointer", background:'white'}}>
              
              <TableCell component="th" scope="row"
 
              >
                 <div style={{color:'red', float: "right"}}>ICON</div>
                {row.name} 
              
              </TableCell>
         
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
