import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import SignupHeader from "./SignupHeader";
import Paper from "@mui/material/Paper";
import "./Signup.css";
import Footer from "./SignupFooter";
import NativeSelect from "@mui/material/NativeSelect";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { sendSignInLinkToEmail } from "firebase/auth";
import { linkWithCredential, EmailAuthProvider } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import { firestore } from "../../firebaseConfig";
import { sendEmailVerification } from "firebase/auth";
import firebase from "firebase/compat/app"; //v9
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = ["Kleidung", "Gastronomie", "Mode", "Elektronik"];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const steps = ["Konto Typ", "Kontoerstellung", "Verifizierung"];

const useHelperTextStyles = makeStyles(() => ({
  root: {
    color: "red",
  },
}));

function Signup(props, str) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [accounttype, setaccounttype] = React.useState("");
  const [selected, hasError] = React.useState("");
  const [VorundNachname, setvorundNachname] = React.useState("");
  const [
    GeschäftsKonto_VorundNachname,
    setGeschäftsKonto_VorundNachname,
  ] = React.useState("");
  const [GeschäftsKonto_Email, setGeschäftsKonto_Email] = React.useState("");
  const [
    GeschäftsKonto_Mobiltelefonnummer,
    setGeschäftsKonto_Mobiltelefonnummer,
  ] = React.useState("");
  const [GeschäftsKonto_password, setGeschäftsKonto_Password] = React.useState(
    ""
  );
  const [
    GeschäftsKonto_RepPassword,
    setGeschäftsKonto_RepPassword,
  ] = React.useState("");
  const [Geschäftsname, setGeschäftsname] = React.useState("");
  const [Märkte, setMärkte] = React.useState([]);
  const [Kontoinhaber, setkontoinhaber] = React.useState("");
  const [IBAN, setIBAN] = React.useState("");
  const [ShippingTo, setShippingTo] = React.useState([]);
  const [SwiftCode, setSwiftCode] = React.useState("");

  const [Email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [RepPassword, setRepPassword] = React.useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  if (GeschäftsKonto_RepPassword == "") {
    var helpertextRepPassword = (
      <p style={{ color: "red" }}>Kennwort nochmals eingeben*</p>
    );
  }

  if (GeschäftsKonto_RepPassword != "") {
    var helpertextRepPassword = (
      <p style={{ display: "none" }}>Kennwort nochmals eingeben*</p>
    );
  }

  if (GeschäftsKonto_password == "") {
    var helpertextpassword = <p style={{ color: "red" }}>Kennwort eingeben*</p>;
  }

  if (GeschäftsKonto_password != "") {
    var helpertextpassword = (
      <p style={{ display: "none" }}>Kennwort eingeben*</p>
    );
  }

  if (SwiftCode == "") {
    var helpertextSwiftCode = (
      <p style={{ color: "red" }}>Swift-code eingeben*</p>
    );
  }

  if (SwiftCode != "") {
    var helpertextSwiftCode = (
      <p style={{ display: "none" }}>Swift-code eingeben*</p>
    );
  }

  if (IBAN == "") {
    var helpertextIBAN = <p style={{ color: "red" }}>IBAN eingeben*</p>;
  }

  if (IBAN != "") {
    var helpertextIBAN = <p style={{ display: "none" }}>IBAN eingeben*</p>;
  }

  if (Kontoinhaber == "") {
    var helpertextKontoinhaber = (
      <p style={{ color: "red" }}>Bankkontoinhaber eingeben*</p>
    );
  }

  if (Kontoinhaber != "") {
    var helpertextKontoinhaber = (
      <p style={{ display: "none" }}>Bankkontoinhaber eingeben*</p>
    );
  }

  if (ShippingTo == "") {
    var helpertexShippingTo = (
      <p style={{ color: "red" }}>Liefergebiete eingeben*</p>
    );
  }

  if (ShippingTo != "") {
    var helpertexShippingTo = (
      <p style={{ display: "none" }}>Liefergebiete eingeben*</p>
    );
  }

  if (Märkte == "") {
    var helpertextMarket = (
      <p style={{ color: "red" }}>Market/Märkte auswählen**</p>
    );
  }

  if (Märkte != "") {
    var helpertextMarket = (
      <p style={{ display: "none" }}>Market/Märkte auswählen*</p>
    );
  }

  if (Geschäftsname == "") {
    var helpertextGeschäftsname = (
      <p style={{ color: "red" }}>Geschäftsname eingeben*</p>
    );
  }

  if (Geschäftsname != "") {
    var helpertextGeschäftsname = (
      <p style={{ display: "none" }}>Geschäftsname eingeben*</p>
    );
  }

  if (GeschäftsKonto_Mobiltelefonnummer == "") {
    var helperhelperGftsKonto_Motelefonnummer = (
      <p style={{ color: "red" }}>Handynummer eingeben*</p>
    );
  }

  if (GeschäftsKonto_Mobiltelefonnummer != "") {
    var helperhelperGftsKonto_Motelefonnummer = (
      <p style={{ display: "none" }}>Handynummer eingeben*</p>
    );
  }

  if (VorundNachname == "" || GeschäftsKonto_VorundNachname == "") {
    var helperTextMess = (
      <p style={{ color: "red" }}>Vor- und Nachname eingeben*</p>
    );
  }

  if (VorundNachname != "" || GeschäftsKonto_VorundNachname !== "") {
    var helperTextMess = (
      <p style={{ display: "none" }}>vor- und Nachname eingeben</p>
    );
  }

  if (Email == "" || GeschäftsKonto_Email == "") {
    var Email_helperTextMess = (
      <p style={{ color: "red" }}>E-Mail Adresse eingeben*</p>
    );
  }

  if (Email != "" || GeschäftsKonto_Email !== "") {
    var Email_helperTextMess = (
      <p style={{ display: "none" }}>E-Mail Adresse eingeben</p>
    );
  }

  if (password == "") {
    var Password_helperTextMess = (
      <p style={{ color: "red" }}>Kennwort eingeben*</p>
    );
  }

  if (password != "") {
    var Password_helperTextMess = (
      <p style={{ display: "none" }}>Kennwort eingeben</p>
    );
  }

  if (RepPassword == "") {
    var RepPass_helperTextMess = (
      <p style={{ color: "red" }}>Kennwort nochmals eingeben*</p>
    );
  }

  if (RepPassword != "") {
    var RepPass_helperTextMess = (
      <p style={{ display: "none" }}>Kennwort nochmals eingeben</p>
    );
  }

  if (accounttype == 0) {
    var helperMessage = (
      <p style={{ fontWeight: "bold", color: "red" }}>
        Bitte wähle den passenden Kontotype aus{" "}
      </p>
    );
  }
  if (accounttype != 0) {
    var helperMessage = (
      <p style={{ display: "none" }}>Bitte wähle den passenden Kontotype aus</p>
    );
  }

  // After complete the steps go to login page
  if (activeStep == 3) {
    navigate("/anmelden");
  }

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "foobar",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleShippingTo = (e, value) => {
    setShippingTo(value);
  };

  function CreateNewBuyerAccount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        // Add a new document in collection "Buyers"
        firestore
          .collection("Buyers")
          .doc(uid)
          .set({
            VorundNachname: VorundNachname,
            Email: user.email,
            Accounttype: accounttype,
          })
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });

        // ...
      } else {
        // User is signed out
        // ...
      }
    });

    if (accounttype === "Käufer") {
      createUserWithEmailAndPassword(auth, Email, "password")
        .then((userCredential) => {
          // Signed in

          var user = firebase.auth().currentUser;
          user
            .sendEmailVerification()
            .then(function() {
              // Email sent.
              console.log("Email sent");
            })
            .catch(function(error) {
              // An error happened.
              console.log("An error happened: ", error);
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
    }
  }

  if (accounttype === "Verkäufer") {
    createUserWithEmailAndPassword(auth, GeschäftsKonto_Email, "password")
      .then((userCredential) => {
        // Signed in

        var user = firebase.auth().currentUser;
        user
          .sendEmailVerification()
          .then(function() {
            // Email sent.
            console.log("Email sent");
          })
          .catch(function(error) {
            // An error happened.
            console.log("An error happened: ", error);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }

  function CreateNewSellerAccount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        // Add a new document in collection "Buyers"

        firestore
          .collection("Sellers")
          .doc(uid)
          .set({
            VorundNachname: GeschäftsKonto_VorundNachname,
            Email: user.email,
            Accounttype: accounttype,
            Geschäftsname: Geschäftsname,
            Mobiltelefonnummer: GeschäftsKonto_Mobiltelefonnummer,
            Märkte: Märkte,
            ShippingTo: ShippingTo,
            BankKontoinhaber: Kontoinhaber,
            IBAN: IBAN,
            SwiftCode: SwiftCode,
          })
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
        // ...
      } else {
        // User is signed out
        // ...
      }
    });

    createUserWithEmailAndPassword(
      auth,
      "abdulkadersulaiman9@gmail.com",
      "password"
    )
      .then((userCredential) => {
        // Signed in

        var user = firebase.auth().currentUser;
        user
          .sendEmailVerification()
          .then(function() {
            // Email sent.
            console.log("Email sent");
          })
          .catch(function(error) {
            // An error happened.
            console.log("An error happened: ", error);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });

  const handleMärkteChange = (event) => {
    const {
      target: { value },
    } = event;
    setMärkte(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleAccounttypeChange = (event) => {
    setaccounttype(event.target.value);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSendSelection = (event) => {
    console.log("value - ", event.target.value);
  };

  const [name, setName] = useState();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register("", { required: true });

  const currentName = watch("name");

  function onSubmit(data) {
    setName(data.name);
    reset();
  }

  // ==========================================
  function handleSelect12Change(e) {
    let isChecked = e.target.checked;

    console.log(isChecked);
    // do whatever you want with isChecked value
  }

  function getChckeboxValue(event) {
    const value = event.target.value;
    console.log(value);
  }
  // ==========================================
  const helperTextStyles = useHelperTextStyles();
  return (
    <>
      <SignupHeader />

      <Box className="stepperContainer">
        <h2 className="signupTitle">Neues Nearwide Konto erstellen</h2>
        <Paper className="Paper" elevation={1}>
          <Stepper activeStep={activeStep} className="Stepper">
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <>
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                </>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

              {activeStep + 1 === 1 && (
                <div className="accountTypeBox">
                  <FormControl
                    sx={{ m: 1, minWidth: 320 }}
                    onSubmit={formik.handleSubmit}
                    required
                  >
                    <TextField
                      required
                      value={accounttype}
                      onChange={(e) => setaccounttype(e.target.value)}
                      select // tell TextField to render select
                      label="Kontotype auswählen"
                      style={{ borderRadius: "4px" }}
                      labelId="select-label-id"
                      id="select-id"
                      className="select_accounttype_Box"
                      displayEmpty
                      helperText={helperMessage}
                      variant="outlined"
                      FormHelperTextProps={{
                        classes: {
                          root: helperTextStyles.root,
                        },
                      }}
                    >
                      {/* <MenuItem value="" style={{display:'none'}}>Käufer</MenuItem> */}
                      <MenuItem value={"Käufer"}>Käufer</MenuItem>
                      <MenuItem value={"Verkäufer"}>Verkäufer</MenuItem>
                    </TextField>
                    {errors.name && <span>This field is required</span>}
                  </FormControl>

                  <Box sx={{ "& button": { m: 1 } }}>
                    <div>
                      <Button
                        disabled={accounttype == 0}
                        onClick={handleNext}
                        variant="contained"
                        size="medium"
                        style={{
                          // background:'rgb(0, 127, 255)',
                          borderRadius: "20px",
                          textTransform: "none",
                          minWidth: "300px",
                          minHeight: "42px",
                        }}
                      >
                        Weiter
                      </Button>
                    </div>
                  </Box>
                  <div style={{ paddingTop: "10px" }}>
                    <Typography variant="p">
                      Du hast schon einen Account?
                      <span
                        style={{ color: "#007FFF", cursor: "pointer" }}
                        onClick={() => {
                          navigate("/anmelden");
                        }}
                      >
                        {" "}
                        Anmelden
                      </span>
                    </Typography>
                  </div>
                </div>
              )}

              {activeStep + 1 === 2 && (
                <div>
                  <Box
                    component="form"
                    className=""
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      {accounttype === "Käufer" && (
                        <>
                          <div>
                            <h3 id="h3_Title">Die Kontaktangaben</h3>
                            <form onSubmit={formik.handleSubmit}>
                              <TextField
                                type="text"
                                required
                                id="textField"
                                label="Vor- und Nachname"
                                onChange={(evt) => {
                                  setvorundNachname(evt.target.value);
                                }}
                                helperText={helperTextMess}
                              />
                              <TextField
                                type="email"
                                id="textField"
                                label="E-Mail-Adresse"
                                onChange={(evt) => {
                                  setEmail(evt.target.value.replace(/\s/g, ""));
                                }}
                                helperText={Email_helperTextMess}
                              />

                              {/* <TextField
                             id="textField"
                              label="Mobiltelefonnummer"
                            /> */}
                              <h3 id="h3_Title">Kennwort erstellen</h3>
                              <TextField
                                id="password_TextField"
                                label="Kennwort"
                                type="password"
                                onChange={(evt) => {
                                  setPassword(
                                    evt.target.value.replace(/\s/g, "")
                                  );
                                }}
                                helperText={Password_helperTextMess}
                                autoComplete="current-password"
                              />
                              <TextField
                                id="password_TextField"
                                label="Kennwort nochmals eingeben"
                                type="password"
                                onChange={(evt) => {
                                  setRepPassword(
                                    evt.target.value.replace(/\s/g, "")
                                  );
                                }}
                                helperText={RepPass_helperTextMess}
                              />
                            </form>
                          </div>
                        </>
                      )}
                      {accounttype === "Verkäufer" && (
                        <>
                          <h3 id="h3_Title">Die Kontaktangaben</h3>
                          <TextField
                            required
                            id="textField"
                            label="Vor- und Nachname"
                            onChange={(evt) => {
                              setGeschäftsKonto_VorundNachname(
                                evt.target.value
                              );
                            }}
                            helperText={helperTextMess}
                          />

                          <TextField
                            id="textField"
                            label="E-Mail-Adresse"
                            onChange={(evt) => {
                              setGeschäftsKonto_Email(
                                evt.target.value.replace(/\s/g, "")
                              );
                            }}
                            helperText={Email_helperTextMess}
                          />

                          <TextField
                            id="textField"
                            label="Mobiltelefonnummer"
                            onChange={(e) =>
                              setGeschäftsKonto_Mobiltelefonnummer(
                                e.target.value.replace(/\s/g, "")
                              )
                            }
                            helperText={helperhelperGftsKonto_Motelefonnummer}
                          />

                          <h3 id="h3_Title">Die Geschäftsangaben</h3>
                          <TextField
                            required
                            id="textField"
                            label="die Geschäftsname"
                            onChange={(e) => setGeschäftsname(e.target.value)}
                            helperText={helpertextGeschäftsname}
                          />
                          <FormControl sx={{ m: 1, minWidth: 330 }}>
                            <InputLabel id="demo-multiple-checkbox-label">
                              deine Produktmärkte
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              value={Märkte}
                              onChange={handleMärkteChange}
                              input={
                                <OutlinedInput label="Ihre Produktmärkte" />
                              }
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                            >
                              {names.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox
                                    checked={Märkte.indexOf(name) > -1}
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                            <div
                              style={{
                                fontSize: "13px",
                                fontFamily: 'Roboto", sans-serif',
                              }}
                            >
                              {helpertextMarket}
                            </div>
                          </FormControl>

                          <p id="delivery_Locations_Title">
                            Wie weit wollen Sie Ihre Produkte liefern ? Bitte
                            auswählen
                          </p>
                          {/* Here Started */}
                          <Stack spacing={3} sx={{ width: 500 }}>
                            <Autocomplete
                              multiple
                              options={GermanCities}
                              onChange={handleShippingTo}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                    deleteIcon={<ClearIcon />}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // variant="filled"
                                  label="Die Deutschland's Städte"
                                  placeholder="Wählen Sie die Städte aus, in die Ihre Produkte versendet werden"
                                />
                              )}
                            />
                          </Stack>
                          {/* Here End */}
                          <FormGroup id="delivery_to_all_German_Cities">
                            <p className="delivery_Locations_Title">
                              Wenn Sie in ganz Deutschland liefern wollen. Bitte
                              wählen Sie die untere Option aus
                            </p>
                            <FormControlLabel
                              onClick={() => {
                                setShippingTo(["Deutschlandweit"]);
                              }}
                              control={<Checkbox />}
                              label="Lieferung in ganz Deutschland"
                            />
                            <div
                              style={{
                                fontSize: "13px",
                                fontFamily: 'Roboto", sans-serif',
                              }}
                            >
                              {helpertexShippingTo}
                            </div>
                          </FormGroup>

                          <br />
                          <h3>Bankkonto</h3>

                          <TextField
                            onChange={(e) => setkontoinhaber(e.target.value)}
                            required
                            id="textField"
                            label="Kontoinhaber"
                            helperText={helpertextKontoinhaber}
                          />

                          <TextField
                            onChange={(e) =>
                              setIBAN(e.target.value.replace(/\s/g, ""))
                            }
                            required
                            id="textField"
                            label="IBAN"
                            helperText={helpertextIBAN}
                          />

                          <TextField
                            required
                            id="textField"
                            label="BIC (Swift-Code)"
                            onChange={(e) =>
                              setSwiftCode(e.target.value.replace(/\s/g, ""))
                            }
                            helperText={helpertextSwiftCode}
                          />

                          <h3 id="h3_Title">Kennwort erstellen</h3>
                          <TextField
                            id="password_TextField"
                            label="Kennwort"
                            type="password"
                            autoComplete="current-password"
                            onChange={(evt) => {
                              setGeschäftsKonto_Password(
                                evt.target.value.replace(/\s/g, "")
                              );
                            }}
                            helperText={helpertextpassword}
                          />
                          <TextField
                            id="password_TextField"
                            label="Kennwort nochmals eingeben"
                            type="password"
                            onChange={(evt) => {
                              setGeschäftsKonto_RepPassword(
                                evt.target.value.replace(/\s/g, "")
                              );
                            }}
                            helperText={helpertextRepPassword}
                          />
                        </>
                      )}
                    </div>
                  </Box>
                </div>
              )}

              {activeStep + 1 === 3 && accounttype === "Käufer" && (
                <div>
                  <br />
                  <h3>EMail-Adresse Verifizierung</h3>
                  <h5 style={{ lineHeight: "1.9" }}>
                    Zur Überprüfung deine Email haben wir einen Bestätigungslink
                    an &nbsp;
                    {Email} gesendet. Bitte überprüfe deine Email Posteingang
                    oder Spam Eingang und bestätige deine E Mail Adresse.
                  </h5>
                  <br />
                </div>
              )}
              {activeStep + 1 === 3 && accounttype === "Verkäufer" && (
                <div>
                  <br />
                  <h3>EMail-Adresse Verifizierung</h3>
                  <h5 style={{ lineHeight: "1.9" }}>
                    Zur Überprüfung deine Email haben wir einen Bestätigungslink
                    an &nbsp;
                    {GeschäftsKonto_Email} gesendet. Bitte überprüfe deine Email
                    Posteingang oder Spam Eingang und bestätige deine E Mail
                    Adresse.
                  </h5>
                  <br />
                </div>
              )}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                {activeStep != 0 && (
                  <Button
                    style={{ fontWeight: "bold", textTransform: "none" }}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    zurück
                  </Button>
                )}
                <Box sx={{ flex: "1 1 auto" }} />

                {/* Show this Button for Käufer account  */}
                {activeStep != 0 && accounttype === "Käufer" && (
                  <Button
                    disabled={
                      VorundNachname == "" ||
                      Email == "" ||
                      password == "" ||
                      RepPassword == ""
                    }
                    style={{
                      textTransform: "none",
                      margin: "auto",
                      fontWeight: "bold",
                    }}
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1
                      ? "Konto Fertigstellen"
                      : "Weiter"}
                  </Button>
                )}

                {/* Show this Button for Käufer account  */}
                {activeStep != 0 && accounttype === "Verkäufer" && (
                  <Button
                    disabled={
                      GeschäftsKonto_VorundNachname == "" ||
                      GeschäftsKonto_Email == "" ||
                      GeschäftsKonto_Mobiltelefonnummer == "" ||
                      Geschäftsname == "" ||
                      Märkte == "" ||
                      ShippingTo == "" ||
                      Kontoinhaber == "" ||
                      IBAN == "" ||
                      SwiftCode == "" ||
                      GeschäftsKonto_password == "" ||
                      GeschäftsKonto_RepPassword == ""
                    }
                    style={{ margin: "auto", fontWeight: "bold" }}
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1
                      ? "fertigstellen"
                      : "Weiter"}
                  </Button>
                )}

                {activeStep === steps.length - 1 &&
                  accounttype === "Käufer" && <>{CreateNewBuyerAccount()}</>}

                {activeStep === steps.length - 1 &&
                  accounttype === "Verkäufer" && (
                    <>{CreateNewSellerAccount()}</>
                  )}
              </Box>
            </React.Fragment>
          )}
        </Paper>

        {activeStep == 0 && (
          <>
            {/* <div style={{textAlign:'center', top:'20px', paddingLeft:'10px', margin:'10px'}}>
           <span style={{color:'#1976d2', cursor:'pointer', margin:'10px'}} onClick={() => {
            navigate("/suche");}}>AGB</span>
            <span style={{color:'#1976d2', cursor:'pointer', paddingLeft:'10px'}} onClick={() => {
            navigate("/suche");}}>AGB</span>
              <span style={{color:'#1976d2', cursor:'pointer', paddingLeft:'10px'}} onClick={() => {
            navigate("/suche");}}>AGB</span>
           </div> */}
          </>
        )}
      </Box>
      <Footer />
    </>
  );
}

// A List of all the German Cities
const GermanCities = [
  "Künzelsau",
  "Öhringen",
  "Heilbronn",
  "Hamburg",
  "Berlin",
  "Ingelfingen",
  "Neuenstein",
];
export default Signup;
