import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
//import firebase from "../../firebaseConfig";
import Searchbox from "../../components/Home_Searchbox/Searchbox";
import firebase from "firebase/compat/app"; //v9
import Slider from "../../components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import "./Elektronik.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {Button} from "@mui/material";


function Elektronik() {
  const navigate = useNavigate();
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);
  const Search_Session = sessionStorage.getItem("SearchValue");
  const Location_Session = sessionStorage.getItem("Location");

  useEffect(() => {
    const getProductsFromFirebase = [];
    const subscriber = firestore
      .collection("products")
      .where("ProductCategory", "==", "Elektronik / ") // Add this filter
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getProductsFromFirebase.push({
            ...doc.data(), //spread operator
            key: doc.id, // `id` given to us by Firebase
          });
        });
        setProduct(getProductsFromFirebase);
        setLoading(false);
      });

    // return cleanup function
    return () => subscriber();
  }, [loading]); // empty dependencies array => useEffect only called once

  if (loading) {
    return (
      <div className="Loader">
        <div className="lds-hourglass"></div>
      </div>
    );
  }

  return (
    <>
      {/* <Grid container className='CustomLeftMarginForHome '>
             <Sidebar />
             <HomePage /> 
         </Grid> */}

      <Header />
       
      <div className="Searchbox">
        <Searchbox />
      </div>

      <br />
  

      <h5
        className="categorieTitle"
        style={{
          position: "relative",
          left: "8%",
          fontFamily: "Arial, sans-serif",
          color: "#333",
          fontSize: "1.5rem",
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >

<Button
          style={{ textTransform: "none", bottom: "40px"}}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon /> Zurück
        </Button>
        
      <br />

        Elektronik
      </h5>

      <div className="grid-container">
        {product.length > 0 ? (
          <>
            {product
              // .filter((Product) => Product.GermanyWideShipping)
              .map((Product) => (
                <div
                  key={Product.key}
                  className="grid-item"
                  onClick={() => {
                    navigate(`suche/Productid/${Product.ProductID}`);
                  }}
                >
                  <div className="product-img">
                    <img src={Product.Card_imgURL} alt="Girl in a jacket" />
                  </div>
                  <div className="Product-details">
                    <div className="Product-Name">{Product.ProductName}</div>
                    <div className="Product-Price">
                      {Product.Product_Price}€
                    </div>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <h1>Keine Ergebnisse gefunden!</h1>
        )}
      </div>

      <div style={{ textAlign: "center" }} className="HomeContainer"></div>

      <Footer />
    </>
  );
}

export default Elektronik;
