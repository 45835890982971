import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, InputAdornment } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import PageHeader from './PageHeader.js';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Footer from '../../components/Footer/Footer.js';
import "./Search.css";
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@material-ui/core/styles';
import Products from '../../components/ProductCard/ProductCard'
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import Header from '../../components/Header/Header.js';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  deleteIcon2: {
    '& svg': {
      fontSize: 40
    }
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

// console.log("=====", window.location.href)


BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);
  const [LocationState, SetLocationState] = useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [OpenSearchAutoComp, setOpenSearchAutoCompOpen] = React.useState(false);
  const [EntersearchValue, setEntersearchValue] = useState('');
  const [ClicksearchValue, setClicksearchValue] = useState('');
  const navigate = useNavigate();
  if( SearchSession != "" ) {
    var SearchSession = sessionStorage.getItem("SearchValue");
  }
 
  if( Location_Session != null  ) {
    var Location_Session =  sessionStorage.getItem("Location");
  }
  


  function handleLocationState (event, value) {
    SetLocationState(value)
     sessionStorage.setItem("Location", value);
  }
  

  const handleTextInputChange = event => {
    setEntersearchValue(event.target.value);
};

    if(LocationState == null) {
      SetLocationState("Deutschlandweit")
    }
        

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.title
  });
  
  const handleOpen = () => {
    if (inputValue.length > 0) {
      setOpenSearchAutoCompOpen(true);
    }
  };

  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    
    sessionStorage.setItem("SearchValue", newInputValue);
  
    if (newInputValue.length > 0) {
      setOpenSearchAutoCompOpen(true);
    } else {
      setOpenSearchAutoCompOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("do validate");
      //  console.log(EntersearchValue)

    }
  };
  
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  //console.log(LocationState || "Deutschlandweit");
  const valueRef = useRef('') //creating a refernce for TextField Component

  const sendValue = () => {
      return console.log(valueRef.current.value) //on clicking button accesing current value of TextField and outputing it to console 
  }
  const Clicksearch = ClicksearchValue;
  const Entersearch = EntersearchValue;


  if (sessionStorage.getItem("Location") == null ) {
    sessionStorage.setItem("Location", "Deutschlandweit")
  }

  return (
 <>
 
 {/* <Header /> */}
 <Header />
        <br />
        <div className="search-container" style={{maxWidth:'50%', margin:'auto'}}>
          
        <Autocomplete
        freeSolo
        // freeSolo={true}
        filterOptions={filterOptions}
        id="combo-box-demo"
        open={OpenSearchAutoComp}
        onOpen={handleOpen}
        onClose={() => setOpenSearchAutoCompOpen(false)}
        inputValue={SearchSession}
        onInputChange={handleInputChange}
        options={SearchSuggestions}
        // getOptionLabel={(option) => option.title}
        style={{ maxWidth: "100%",
      
      }}
      
        renderInput={(params) => (
          <TextField
         className="TextField_SearchBox"
         placeholder={Location_Session}
            onKeyDown={handleKeyDown}
            label={
                sessionStorage.getItem("Location")
            }
            variant="outlined"
            {...params}
            InputProps={{
              endAdornment: (
                
                <Button
                className={classes.deleteIcon2}
                id="SearchBtn"
                style={{ marginRight: 0, maxHeight: "45px", ...(SearchSession !== "" ? { left: '30px' } : {}) }}
                onClick={(e) => setClicksearchValue(EntersearchValue)}
              >
                <SearchIcon className={classes.deleteIcon2} />
              </Button>
              
                
              ),

              startAdornment: (
                <InputAdornment position="start" style={{fontSize: "33px"}}>
                  <IconButton color="primary" aria-label="add an alarm"
                  onClick={handleClickOpen}
                  >
                    <AddLocationAltTwoToneIcon  style={{fontSize: "33px"}}/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange= {handleTextInputChange}
           
          />
        )}
      />
       <br />
      <br />
      <br />
      <Button
          style={{ textTransform: "none", bottom: "50px" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon /> Zurück
        </Button>
      </div>
      <Products 
      Clicksearch={Clicksearch} 
      Entersearch={Entersearch}
      LocationState={LocationState}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Ort auswählen
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Autocomplete
              onChange={handleLocationState} // prints the selected value
              freeSolo
              id="free-solo-2-demo"
              
              options={GermanCities.map((option) => option.city)}
              renderInput={(params) => (
                <TextField
                variant="outlined"
                label="Suche nach einer Stadt"
                 style={{ minWidth: "100%" }}
                  {...params}
                  // InputProps={{
                  //   ...params.InputProps,
                  //   type: "search"
                  // }}
                  
                  
                />
              )}
            />
  
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '13px' }}>
  <label htmlFor="germanySearch" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
    <Checkbox
      id="germanySearch"
      {...label}
      sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
      onClick={() => {
        SetLocationState('Deutschlandweit');
        sessionStorage.setItem("Location", 'Deutschlandweit');
        // Additional functionality or comments can be added below
        // For example, you can trigger other actions or log messages
        // console.log("Checkbox clicked: Set to Deutschlandweit");
      }}
    />
    <span style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>In ganz Deutschland suchen</span>
  </label>
</div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Okay
          </Button>
        </DialogActions>
      </BootstrapDialog>
     </>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const SearchSuggestions = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "Essen", year: 1974 },
  { title: "B Godfather: Part II", year: 1974 },
  { title: "A Godfather: Part II", year: 1974 }
  // and many more options
];
const GermanCities = [

  { city: "Heilbronn",    Plz: 74072, Bundesland: "Baden-Württemberg" },
  { city: "Ingelfingen",  Plz: 74653, Bundesland: "Baden-Württemberg" },
  { city: "Künzelsau",    Plz: 74653, Bundesland: "Baden-Württemberg" },
  { city: "Öhringen",     Plz: 74613, Bundesland: "Baden-Württemberg" },
  // { city: "Bretzfeld",    Plz: 74626, Bundesland: "Baden-Württemberg" },
  // { city: "Dörzbach",     Plz: 74677, Bundesland: "Baden-Württemberg" },
  // { city: "Forchtenberg", Plz: 74670, Bundesland: "Baden-Württemberg" },
  // { city: "Krautheim",    Plz: 74238, Bundesland: "Baden-Württemberg" },
  // { city: "Kupferzell",   Plz: 74635, Bundesland: "Baden-Württemberg" },
  // { city: "Mulfingen",    Plz: 74673, Bundesland: "Baden-Württemberg" },
  // { city: "Neuenstein",   Plz: 74632, Bundesland: "Baden-Württemberg" },
  // { city: "Niedernhall",  Plz: 74676, Bundesland: "Baden-Württemberg" },
  // { city: "Pfedelbach",   Plz: 74629, Bundesland: "Baden-Württemberg" },
  // { city: "Schöntal",     Plz: 74214, Bundesland: "Baden-Württemberg" },
  // { city: "Waldenburg",   Plz: 74638, Bundesland: "Baden-Württemberg" },
  // { city: "Weißbach",     Plz: 74679, Bundesland: "Baden-Württemberg" },
  // { city: "Zweiflingen",  Plz: 74639, Bundesland: "Baden-Württemberg" },
];
