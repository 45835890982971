import React, { useEffect, useState, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
import LanguageIcon from "@mui/icons-material/Language";
import firebase from "firebase/compat/app"; //v9
import { firestore, storage, db, auth } from "../../firebaseConfig";
import { getAuth } from "firebase/auth";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PlaceIcon from '@mui/icons-material/Place';

import "./Header.css";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("background", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  [theme.breakpoints.down("sm")]: {
    width: 0,
    display: "none",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const HomePath = document.location.pathname;
  const [user, setUser] = useState();
  const [Accounttype, setAccounttype] = useState("");
  const navigate = useNavigate();
  const [userState, setuserState] = React.useState(false);
  const [loginState, setloginState] = React.useState(false);

  const auth = getAuth();

  const drawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleDrawerClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerRef]);

  // check account type if 'Seller' to show Dashboard Button
  useEffect(() => {
    const sellersCollection = firestore.collection("Sellers");

    auth.onAuthStateChanged((user) => {
      if (user) {
        const userUid = user.uid;
        setloginState(true);
        sellersCollection
          .where("uid", "==", userUid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              if (data.Accounttype === "Seller") {
                setAccounttype("Seller");
              } else {
                setAccounttype("");
              }
            });
          })
          .catch((error) => {
            console.error("Error retrieving Sellers collection:", error);
          });
      }
    });
  }, []); // Empty dependency array to run the effect only once

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        // console.log("User is signed in. =>", user.email)
        setuserState(true);
      } else {
        // No user is signed in.
        // console.log("No user is signed in.")
      }
    });
  }, []);

  function SignOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        setuserState(false);
      })
      .catch((error) => {
        // An error happened.
      });
    navigate("/anmelden");
  }

  if (document.location.pathname !== "/ProfilePage") {
    return (
      <Box sx={{ display: "flex" }} className="search-page-container">
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          style={{ background: "#fff", color: "rgb(138, 141, 145)" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box display="flex" flexGrow={1}>
              {/* whatever is on the left side */}
              {open == false && (
            <Link to="/" style={{ textDecoration: "none" }}>
            <Typography
              variant="h5"
              noWrap
              component="div"
              style={{
                color: "#007FFF",
                fontFamily: "Arial, serif",
                position: "relative",
                right: "25px",
                cursor: "pointer",  // Add this style to show it's clickable
              }}
            >
              Nearwide
            </Typography>
          </Link>
              )}
              {/* userState */}
            </Box>
            <>
              {userState == false && (
                <Button
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  startIcon={<PersonIcon />}
                  onClick={() => {
                    navigate("/anmelden");
                  }}
                >
                  Anmelden
                </Button>
              )}
            </>
            <>
              {userState == true && (
                <Button
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  startIcon={<PersonIcon />}
                  onClick={SignOut}
                >
                  Abmelden
                </Button>
              )}
            </>
          </Toolbar>
        </AppBar>

        <Drawer ref={drawerRef} variant="permanent" open={open}>
          <DrawerHeader>
            <Typography
              variant="h5"
              noWrap
              component="div"
              style={{ color: "#007FFF", fontFamily: "Arial, serif" }}
            >
              Nearwide
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          {/* <Divider /> */}
          <div style={{ position: "relative", bottom: "2px" }}>
            {Accounttype == "Seller" && (
              <>
                <List>
                  {["Dashboard"].map((text, index) => (
                    <ListItem
                      button
                      key={text}
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      <ListItemIcon>
                        <StorefrontIcon />
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
                <hr />
              </>
            )}

            {Accounttype != "Seller" && (
              <>
                <br />
                <hr />
              </>
            )}

            {/* {loginState == true && (
             <>
              <List>
              {["Meine Bestellungen"].map((text, index) => (
                <ListItem button key={text} style={{position: "relative", bottom: "15px"}}
                 onClick={()=>{navigate('/user/123')}}
                 >
                  <ListItemIcon>
                  <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
             </>
            )} */}

            {/* {loginState == false && (
             <>
              <List>
              {["Meine Bestellungen"].map((text, index) => (
                <ListItem button key={text} style={{position: "relative", bottom: "15px"}}
                 onClick={()=>{navigate('/anmelden')}}
                 >
                  <ListItemIcon>
                  <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
             </>
            )} */}

            <List>
              {["Startseite"].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>

            <List>
              {["Suche"].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => {
                    navigate("/suche");
                  }}
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>


    <List>
              {["Lokal einkaufen"].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => {
                    navigate("/lokal/einkaufen");
                  }}
                >
                  <ListItemIcon>
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>



            <List>
              {["Meine Bestellungen"].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => {
                    if (loginState) {
                      navigate("/meine-Bestelungen");
                    } else {
                      navigate("/anmelden");
                    }
                  }}
                >
                  <ListItemIcon>
                    <LocalShippingIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>

            <List>
              {["Elektronik"].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => {
                    navigate("/Kategorien/Elektronik");
                  }}
                >
                  <ListItemIcon>
                    <LaptopMacIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>

            <List>
              {["Aktuelle Angebote"].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  style={{ position: "relative", bottom: "15px" }}
                  onClick={() => {
                    // history.push("/electronics");
                  }}
                >
                  <ListItemIcon>
                    <CardGiftcardIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>

            <List>
              {["Deutsch"].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  style={{ position: "relative", bottom: "15px" }}
                  onClick={() => {
                    // history.push("/clothes");
                  }}
                >
                  <ListItemIcon>
                    {/* <CheckroomOutlinedIcon /> */}
                    <LanguageIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
